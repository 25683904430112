import { Payments } from '@models/v2/payment.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as PaymentsActions from './payments.actions';

export interface State extends Payments {
  error: Error;
}

export const initialState: State = {
  links: {},
  selectedLink: {
    loading: false,
  },
  updatePayment: {
    loading: false,
  },
  error: null,
};

const paymentsReducer = createReducer(
  initialState,
  on(PaymentsActions.createPaymentLink, (state, { order }) => ({
    ...state,
    selectedLink: {
      id: order.id,
      loading: true,
    },
    error: null,
  })),

  on(PaymentsActions.updatePaymentStatus, (state) => ({
    ...state,
    updatePayment: {
      loading: true,
    },
  })),
  on(PaymentsActions.updatePaymentStatusSuccess, (state, { result }) => ({
    ...state,
    updatePayment: {
      loading: false,
      ...result,
    },
  })),
  on(PaymentsActions.updatePaymentStatusError, (state, { error }) => ({
    ...state,
    updatePayment: {
      loading: true,
      ...error,
    },
  })),
  on(PaymentsActions.updatePaymentStatus, (state) => ({
    ...state,
    updatePayment: {
      loading: true,
    },
  })),

  on(PaymentsActions.createPaymentLinkSuccess, (state, { link }) => ({
    ...state,
    links: {
      ...state.links,
      [link.data.id]: link.data,
    },
    selectedLink: link.data,
    error: null,
  })),

  on(PaymentsActions.createPaymentLinkError, (state, { error }) => ({
    ...state,
    selectedLink: {
      ...state.selectedLink,
      loading: false,
      error,
    },
  })),

  on(PaymentsActions.reset, () => ({ ...initialState })),
  on(PaymentsActions.error, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return paymentsReducer(state, action);
}
