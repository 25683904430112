import { APPS_URL, BUX_URL, DEFAULT } from '@constants/global.constants';
import { getEnv, getSubDomain } from './env';

export const createTrackingLink = (id: string) => APPS_URL.CUSTOMER.GET_TRACKING_URL(getSubDomain(), id);
export const createMapsLink = (address: string) => APPS_URL.MAPS(address);
export const createPaymentLink = (id: string) => `${BUX_URL[getEnv()]}${id}`;
export const getPublicFile = (name: string) => `${DEFAULT.PUBLIC_STORAGE}/${name}`;
export const getMerchantMarker = () => getPublicFile(DEFAULT.MARKERS.MERCHANT);
export const getAgentMarker = () => getPublicFile(DEFAULT.MARKERS.AGENT);
export const getCustomerMarker = () => getPublicFile(DEFAULT.MARKERS.CUSTOMER);
export const getAgentMarkerCluster = () => getPublicFile(DEFAULT.MARKERS.MARKER_CLUSTER);
export const getThirdPartyMarker = (courier: string) => getPublicFile(DEFAULT.THIRD_PARTY_MARKERS[courier]);
