import { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as firebase from 'firebase/app';
import * as moment from 'moment-timezone';
import { object, list } from 'rxfire/database';
import { map, takeUntil } from 'rxjs/operators';
import { from, of, Subject } from 'rxjs';

// Services
import * as Time from 'src/app/core/utils/time';

// Models & Constants
import { RTDB_CONSTANTS } from 'src/app/core/constants/rtdb.constants';
import { Branches } from 'src/app/models/data/branch.model';
import { Merchant } from 'src/app/models/data/merchant.model';
import { OrderDetails, RealTimeOrder } from 'src/app/models/data/order.model';
import { DeliveryDetails } from 'src/app/models/integration/delivery.model';

// State
import { Store } from '@ngrx/store';
import { StoreRootState } from 'src/app/state/state.reducers';
import * as fromAuth from 'src/app/state/auth/auth.selector';
import { AnalyticsService } from './analytics.service';
import { GlobalDataService } from '@shared/global.shared.service';
import { CloudFunctionsService } from './cloud.functions.services';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class RTDBService {
  private db = firebase.database();
  private MERCHANTS = RTDB_CONSTANTS.MERCHANTS;
  private user: Merchant;
  private merchantUid: string;
  private branches: Branches;
  private hqUid: string;
  private realtimeOrderFunctions = this.cloudFunction.order();
  private agentFunctions = this.cloudFunction.agent();

  constructor(
    private store: Store<StoreRootState>,
    private analyticsService: AnalyticsService,
    private global: GlobalDataService,
    private cloudFunction: CloudFunctionsService
  ) {
    this.store.select(fromAuth.selectUser).subscribe((user: Merchant) => {
      if (user) {
        this.user = user;
        this.merchantUid = user.role === 'owner' ? user?.uid : user?.ownerId;
        this.branches = user?.branches;
        this.hqUid = user?.hq;
        this.analyticsService.setUserProperties({ role: user?.role, brand: user?.profile?.brand });
      }
    });
  }

  /**
   *
   * @param branchUid
   * @param merchantId
   * @param agentId
   * @returns
   */
  getAgentRef(branchUid: string, merchantId: string, agentId: string) {
    return `merchants/${merchantId}/branches/${branchUid}/agents/${agentId}`;
  }

  getDropdownRef() {
    return `dropdown/${this.merchantUid}`;
  }

  getBranchRef(branchUid: string) {
    const uid = branchUid ? branchUid : this.hqUid;
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BRANCHES}/${uid}`;
  }

  getBranchDeliveriesRef(branchUid: string) {
    const uid = branchUid ? branchUid : this.hqUid;
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BRANCHES}/${uid}/${this.MERCHANTS.DELIVERIES}`;
  }

  getBranchOrdersRef(branchUid: string) {
    const uid = branchUid ? branchUid : this.hqUid;
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BRANCHES}/${uid}/${this.MERCHANTS.ORDERS}`;
  }

  getBranchAgentsRef(branchUid: string) {
    const uid = branchUid ? branchUid : this.hqUid;
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BRANCHES}/${uid}/${this.MERCHANTS.AGENTS}`;
  }

  getBranchLocationRef(branchUid: string) {
    const uid = branchUid ? branchUid : this.hqUid;
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BRANCHES}/${uid}/${this.MERCHANTS.LOCATION}`;
  }

  getBranchOrderRef(branchUid: string, orderDetails: OrderDetails) {
    const branchOrdersRef = this.getBranchOrdersRef(branchUid);
    const todayObj = Time.getDateinTimeZone(orderDetails.acceptedAt, orderDetails?.timezone);
    const date = todayObj.date();
    const month = todayObj.month() + 1;
    const year = todayObj.year();
    return `${branchOrdersRef}/${year}/${month}/${date}/${orderDetails.id}`;
  }

  getBranchAggregationRef(branchUid: string) {
    return `${this.getBranchRef(branchUid)}/${this.MERCHANTS.AGGREGATION}`;
  }

  getWhiteLabelAgentsRef(branchId: string) {
    return `${this.getBranchRef(branchId)}/agents`;
  }

  getBranchOrderAggregationRef(branchUid: string) {
    return `${this.getBranchRef(branchUid)}/${this.MERCHANTS.AGGREGATION}/${this.MERCHANTS.ORDERS}`;
  }

  getBranchPriorityAggregationRef(branchUid: string) {
    return `${this.getBranchRef(branchUid)}/${this.MERCHANTS.AGGREGATION}/${this.MERCHANTS.MANAGEMENT}/${
      this.MERCHANTS.PRIORITIES
    }`;
  }

  getBranchPaymentAggregationRef(branchUid: string) {
    return `${this.getBranchRef(branchUid)}/${this.MERCHANTS.AGGREGATION}/${this.MERCHANTS.PAYMENTS}`;
  }

  getBranchSalesAggregationRef(branchUid: string) {
    return `${this.getBranchRef(branchUid)}/${this.MERCHANTS.AGGREGATION}/${this.MERCHANTS.SALES}`;
  }

  getBranchDeliveryAggregationRef(branchUid: string) {
    return `${this.getBranchRef(branchUid)}/${this.MERCHANTS.AGGREGATION}/${this.MERCHANTS.DELIVERIES}`;
  }

  getWhiteLabelAgents(branchId: any) {
    const ref = this.db.ref(this.getWhiteLabelAgentsRef(branchId.id));
    const agentref = ref.orderByChild('s').equalTo(0);
    return list(agentref).pipe(
      map((changes) =>
        changes.map((c) => {
          return { id: c.snapshot.key, event: c.event, ...c.snapshot.val() };
        })
      )
    );
  }

  getSelectedBranchLocation(branchId: string) {
    const branchLocationRef = this.db.ref(this.getBranchLocationRef(branchId));
    return object(branchLocationRef).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  listenToDailyBranchOrderAggregation(selectedBranch: string, dateFilter: string) {
    const branchOrderUrl = this.getBranchOrderAggregationRef(selectedBranch);
    const branchRef = this.db.ref(`${branchOrderUrl}/${this.MERCHANTS.DAILY}`).child(dateFilter);
    return object(branchRef).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  listenToMonthlyBranchOrderAggregation(selectedBranch: string, dateFilter: string) {
    const branchOrderUrl = this.getBranchOrderAggregationRef(selectedBranch);
    const branchRef = this.db.ref(`${branchOrderUrl}/${this.MERCHANTS.DAILY}`).child(dateFilter);
    return object(branchRef).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  listenToBranchPriorityAggregation(selectedBranch: string, dateFilter: string) {
    const branchOrderUrl = this.getBranchPriorityAggregationRef(selectedBranch);
    const branchRef = this.db.ref(`${branchOrderUrl}/${this.MERCHANTS.DAILY}`).child(dateFilter);
    return object(branchRef).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  listenToBranchDeliveryAggregation(selectedBranch: string, dateFilter: string) {
    const branchOrderUrl = this.getBranchDeliveryAggregationRef(selectedBranch);
    const branchRef = this.db.ref(`${branchOrderUrl}/${this.MERCHANTS.DAILY}`).child(dateFilter);
    return object(branchRef).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  listenToRealTimeDeliveries(selectedBranch: string, dateFilter: string) {
    const branchDeliveriesUrl = this.getBranchDeliveriesRef(selectedBranch);
    const branchDeliveriesRef = this.db.ref(branchDeliveriesUrl).child(dateFilter);

    return list(branchDeliveriesRef).pipe(
      map((changes) =>
        changes.map((c) => {
          return { id: c.snapshot.key, event: c.event, ...c.snapshot.val() };
        })
      )
    );
  }

  listenToRealTimeOrders(selectedBranch: string, dateFilter: string) {
    const branchOrdersUrl = this.getBranchOrdersRef(selectedBranch);
    const branchOrdersRef = this.db.ref(branchOrdersUrl).child(dateFilter).orderByChild('time');

    return list(branchOrdersRef).pipe(
      map((changes) =>
        changes.map((c) => {
          return { id: c.snapshot.key, event: c.event, ...c.snapshot.val() };
        })
      )
    );
  }

  listenToMonthlyRealTimeOrders(selectedBranch: string, dateFilter: string) {
    const branchOrdersUrl = this.getBranchOrdersRef(selectedBranch);
    const branchOrdersRef = this.db.ref(branchOrdersUrl).child(dateFilter).orderByChild('time');

    return object(branchOrdersRef).pipe(map((changes) => ({ dateFilter, ...changes.snapshot.val() })));
  }

  listenToMonthlyOrdersAggregation(selectedBranch: string, dateFilter: string) {
    const branchOrdersUrl = this.getBranchOrderAggregationRef(selectedBranch);
    const branchOrdersRef = this.db.ref(`${branchOrdersUrl}/${this.MERCHANTS.DAILY}`).child(dateFilter);
    return object(branchOrdersRef).pipe(map((changes) => ({ dateFilter, ...changes.snapshot.val() })));
  }

  listenToRealTimeAgents(selectedBranch: string) {
    const branchAgentsUrl = this.getBranchAgentsRef(selectedBranch);
    const branchAgentsRef = this.db.ref(branchAgentsUrl);
    return list(branchAgentsRef).pipe(
      map((changes) =>
        changes.map((c) => {
          return { id: c.snapshot.key, event: c.event, ...c.snapshot.val() };
        })
      )
    );
  }

  listenToRealTimeDropdown(node: string) {
    const merchantDropdownUrl = this.getDropdownRef();
    const merchantDropdownRef = this.db.ref(merchantDropdownUrl).child(node);
    return object(merchantDropdownRef).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  updateOrderDetails({ branchId, orderDetails, deliveryOrder }) {
    const loc = this.getBranchOrderRef(branchId, orderDetails);
    const deliveryDetails: DeliveryDetails = {
      s: 10,
      co: deliveryOrder?.courier,
      cOId: deliveryOrder?.courierOrderId,
    };
    return from(this.updateData(loc, deliveryDetails));
  }

  updateOrderPriority(branchUid, order, priorityLevel) {
    const loc = this.getBranchOrderRef(branchUid, order);
    const priority = {
      priority: priorityLevel,
    };
    return from(this.updateData(loc, priority));
  }

  removeOrder({ branchId, orderDetails }) {
    const loc = this.getBranchOrderRef(branchId, orderDetails);
    const removedStatus = {
      status: 'removed',
      updateAt: moment().toISOString(),
    };
    return from(this.updateData(loc, removedStatus));
  }

  getDailyReports(branch: string, year: string, month: string, node: string) {
    let dailyReports: firebase.database.Query;
    if (branch === this.merchantUid) {
      dailyReports = this.db.ref(`${this.MERCHANTS.ROOT}/${this.merchantUid}/agg/${node}/d/${year}`);
    } else {
      dailyReports = this.db.ref(`${this.MERCHANTS.ROOT}/${this.merchantUid}/branches/${branch}/agg/${node}/d/${year}`);
    }
    return object(dailyReports).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getMonthlyReports(branch: string, year: string, node: string) {
    let monthlyReports: firebase.database.Query;
    if (branch === this.merchantUid) {
      monthlyReports = this.db.ref(`${this.MERCHANTS.ROOT}/${this.merchantUid}/agg/${node}/m/${year}`);
    } else {
      monthlyReports = this.db.ref(
        `${this.MERCHANTS.ROOT}/${this.merchantUid}/branches/${branch}/agg/${node}/m/${year}`
      );
    }
    return object(monthlyReports).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getWeeklyReports(branch: string, year: string, node: string) {
    let weeklyReports: firebase.database.Query;
    if (branch === this.merchantUid) {
      weeklyReports = this.db.ref(`${this.MERCHANTS.ROOT}/${this.merchantUid}/agg/${node}/w/${year}`);
    } else {
      weeklyReports = this.db.ref(
        `${this.MERCHANTS.ROOT}/${this.merchantUid}/branches/${branch}/agg/${node}/w/${year}`
      );
    }
    return object(weeklyReports).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getDailyPriorities(branch: string, year: string, month: string) {
    const dailyPriorities = this.db.ref(
      `${this.MERCHANTS.ROOT}/${this.merchantUid}/branches/${branch}/agg/management/priorities/d/${year}/${month}`
    );
    return object(dailyPriorities).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getMonthlyPriorities(branch: string, year: string) {
    const monthlyPriorities = this.db.ref(
      `${this.MERCHANTS.ROOT}/${this.merchantUid}/branches/${branch}/agg/management/priorities/m/${year}`
    );
    return object(monthlyPriorities).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getWeeklyPriorities(branch: string, year: string) {
    const monthlyPriorities = this.db.ref(
      `${this.MERCHANTS.ROOT}/${this.merchantUid}/branches/${branch}/agg/management/priorities/w/${year}`
    );
    return object(monthlyPriorities).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getLatestScore(building: string) {
    const latestScore = this.db.ref(`${this.MERCHANTS.ROOT}/${this.merchantUid}/buildings/${building}/rooms`);
    return object(latestScore).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getDailySensor(year: string, month: string, day: string, branch: string, building: string, room: string) {
    const dailySensor = this.db.ref(
      `${this.MERCHANTS.ROOT}/${this.merchantUid}/branches/${branch}/agg/sensors/d/${year}/${month}/${day}/${building}/${room}`
    );
    return object(dailySensor).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  addNewBuildingRoom(docRef, branchId, buildingId, roomInfo) {
    const merchantDropdownUrl = this.getDropdownRef();
    const buildingRef = `${merchantDropdownUrl}/buildings/${branchId}/${buildingId}/rooms/${docRef.id}`;
    this.setData(buildingRef, roomInfo);
  }

  updateBuildingRoom(roomId, branchId, buildingId, roomInfo) {
    const merchantDropdownUrl = this.getDropdownRef();
    const buildingRoomRef = `${merchantDropdownUrl}/buildings/${branchId}/${buildingId}/rooms/${roomId}`;
    this.updateData(buildingRoomRef, roomInfo);
  }

  deleteBuildingRoom(roomId, branchId, buildingId) {
    const merchantDropdownUrl = this.getDropdownRef();
    const buildingRoomRef = `${merchantDropdownUrl}/buildings/${branchId}/${buildingId}/rooms/${roomId}`;
    this.removeData(buildingRoomRef);
  }

  addNewBranch(docRef, branchName) {
    const merchantDropdownUrl = this.getDropdownRef();
    const branchRef = `${merchantDropdownUrl}/branches/${docRef.id}`;
    this.setData(branchRef, branchName);
  }

  updateBranch(branchInfo) {
    const merchantDropdownUrl = this.getDropdownRef();
    const branchRef = `${merchantDropdownUrl}/branches/${branchInfo.uid}`;
    this.setData(branchRef, branchInfo.name);
  }

  updateAgent(agentInfo: any) {
    const agentRef = this.getAgentRef(agentInfo?.branchId, agentInfo?.merchantId, agentInfo?.uid);
    const n = `${agentInfo?.fname} ${agentInfo?.lname}`;
    const p = `${agentInfo?.phone}`;
    const agentData = {
      n,
      p,
    };
    return from(this.updateData(agentRef, agentData));
  }

  deleteBranch(branchInfo) {
    const merchantDropdownUrl = this.getDropdownRef();
    const branchRef = `${merchantDropdownUrl}/branches/${branchInfo.id}`;
    this.removeData(branchRef);
  }

  addNewBuilding(docRef, buildingInfo) {
    const merchantDropdownUrl = this.getDropdownRef();
    const buildingRef = `${merchantDropdownUrl}/buildings/${buildingInfo.branchId}/${docRef.id}`;
    this.setData(buildingRef, buildingInfo);
  }

  updateBuilding(buildingUid, buildingInfo) {
    const merchantDropdownUrl = this.getDropdownRef();
    const buildingRef = `${merchantDropdownUrl}/buildings/${buildingInfo.branchId}/${buildingUid}`;
    this.updateData(buildingRef, buildingInfo);
  }

  deleteBuilding(buildingInfo) {
    const merchantDropdownUrl = this.getDropdownRef();
    const buildingRef = `${merchantDropdownUrl}/buildings/${buildingInfo.branchId}/${buildingInfo.id}`;
    this.removeData(buildingRef);
  }

  setData(loc: string, obj: object) {
    return this.db.ref(loc).set(obj);
  }

  removeData(loc: string) {
    return this.db.ref(loc).remove();
  }

  updateData(loc: string, obj: object) {
    return this.db.ref(loc).update(obj);
  }

  updateOrderStatus(branchId: string, merchantId: string, orderDetails: RealTimeOrder, orderStatus: number) {
    return from(
      this.realtimeOrderFunctions.updateStatus({
        user: this.user,
        branchId,
        merchantId,
        orderDetails,
        orderStatus,
      }) as Promise<any>
    );
  }

  updateAgentStatus(branchId: string, merchantId: string, agentId: string, status: number) {
    return from(this.agentFunctions.updateAgentStatus({ branchId, merchantId, agentId, status }) as Promise<any>);
  }
}
