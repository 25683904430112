import { createAction, props } from '@ngrx/store';

export const GET_IOT_DEVICES = '[IOT Dashboard] Get IOT Devices';
export const GET_LATEST_SCORE = '[IOT Dashboard] Get Latest Score';
export const GET_DAILY_SENSOR = '[IOT Dashboard] Get Daily Sensor';
export const SET_IOT_DEVICES = '[IOT Dashboard] Set IOT Devices';
export const SET_LATEST_SCORE = '[IOT Dashboard] Set Latest Score';
export const SET_DAILY_SENSOR = '[IOT Dashboard] Set Daily Sensor';

export const GET_IOT_HISTORICAL_DATA = '[IOT Dashboard] Get IOT Historical Data';
export const SET_IOT_HISTORICAL_DATA = '[IOT Dashboard] Set IOT Historical Data';

// Selected Dropdown
export const SET_SELECTED_BRANCH = '[IOT Dashboard] Set Selected Branch';
export const SET_SELECTED_BUILDING = '[IOT Dashboard] Set Selected Building';
export const SET_SELECTED_ROOM = '[IOT Dashboard] Set Selected Room';

export const RESET_IOT = '[Content] Reset IOT';
export const ERROR = '[IOT Dashboard] Error';

// GET SENSOR
export const getIOTDevices = createAction(GET_IOT_DEVICES, props<{ building: string }>());
export const getLatestScore = createAction(GET_LATEST_SCORE, props<{ building: string }>());
export const getDailySensorData = createAction(
  GET_DAILY_SENSOR,
  props<{ year: string; month: string; day: string; branch: string; building: string; room: string }>()
);
export const getIOTHistoricalData = createAction(
  GET_IOT_HISTORICAL_DATA,
  props<{ roomId: string; date: string; hour: number }>()
);

// SET SENSOR
export const setIOTDevices = createAction(SET_IOT_DEVICES, props<{ devices: any }>());
export const setLatestScore = createAction(SET_LATEST_SCORE, props<{ score: any }>());
export const setDailySensorData = createAction(SET_DAILY_SENSOR, props<{ daily: any }>());
export const setIOTHistoricalData = createAction(SET_IOT_HISTORICAL_DATA, props<{ historicalData: any }>());

// Selected Dropdowns
export const setSelectedBranch = createAction(SET_SELECTED_BRANCH, props<{ branch: any }>());
export const setSelectedBuilding = createAction(SET_SELECTED_BUILDING, props<{ building: any }>());
export const setSelectedRoom = createAction(SET_SELECTED_ROOM, props<{ room: object }>());

export const reset = createAction(RESET_IOT);

export const error = createAction(ERROR, props<{ error: string }>());
