import { map, catchError, mergeMap, takeUntil, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { AuthService } from 'src/app/services/firebase/auth.service';

import * as SettingsActions from './settings.actions';
import { FirestoreService } from 'src/app/services/firebase/firestore.service';
import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';
import { ManagerService } from 'src/app/services/settings/manager.settings.service';
import { AgentService } from 'src/app/services/settings/agent.settings.service';
import { BranchService } from 'src/app/services/settings/branch.settings.service';
import { RTDBService } from 'src/app/services/firebase/rtdb.service';
import { GlobalDataService } from '@shared/global.shared.service';

@Injectable()
export class SettingsEffects {
  getManagers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.GET_MANAGERS_SETTINGS),
      mergeMap(() =>
        this.fsService.getManagersDetails().pipe(
          takeUntil(this.global.SettingsNotifier$),
          map((managers: any) => {
            return SettingsActions.setManagersSettings({ managers });
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  getBranches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.GET_BRANCHES_SETTINGS),
      mergeMap(() =>
        this.rtdbService.listenToRealTimeDropdown('branches').pipe(
          takeUntil(this.global.SettingsNotifier$),
          map((branches: any) => {
            return SettingsActions.setBranchesSettings({ branches });
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  getBuildingManagers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.GET_BUILDING_MANAGER),
      mergeMap(() =>
        this.fsService.getBuildingManagersDetails().pipe(
          take(1),
          map((buildingManagers: any) => {
            return SettingsActions.setBuildingManager({ buildingManagers });
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  getBuildings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.GET_BUILDINGS_SETTINGS),
      mergeMap(({ branchId }) =>
        this.fsService.getBuildingDetails(branchId).pipe(
          take(1),
          map((buildings: any) => {
            return SettingsActions.setBuildingsSettings({ buildings });
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  getAgents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.GET_AGENTS_SETTINGS),
      mergeMap(({ branchId }) =>
        this.fsService.getAgentsDetails(branchId).pipe(
          take(1),
          map((agents: any) => {
            return SettingsActions.setAgentsSettings({ agents });
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  getRooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.GET_ROOMS_SETTINGS),
      mergeMap(({ branchId, buildingId }) =>
        this.fsService.getBuildingRoomDetails(branchId, buildingId).pipe(
          take(1),
          map((rooms: any) => {
            return SettingsActions.setRoomsSettings({ rooms });
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  createAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.CREATE_AGENT),
      mergeMap(({ agent }) =>
        this.agentService.createAgent(agent).pipe(
          take(1),
          map(() => {
            return SettingsActions.createAgentSuccess({ createAgentSuccess: true });
          }),
          catchError((error) => of(SettingsActions.error({ error })))
        )
      )
    )
  );

  updateAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.UPDATE_AGENT),
      mergeMap(({ agent }) =>
        this.fsService.updateAgent(agent).pipe(
          takeUntil(this.global.SettingsNotifier$),
          map(() => {
            return { agent };
          })
        )
      ),
      mergeMap(({ agent }) =>
        this.rtdbService.updateAgent(agent).pipe(
          takeUntil(this.global.SettingsNotifier$),
          map(() => {
            return SettingsActions.updateAgentSuccess({ updateAgentSuccess: true });
          })
        )
      ),
      catchError((error) => {
        return of(SettingsActions.updateAgentSuccess({ updateAgentSuccess: false }), SettingsActions.error(error));
      })
    )
  );

  createBuildingManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.CREATE_BUILDING_MANAGER),
      mergeMap(({ buildingManager }) =>
        this.managerService.createManager(buildingManager).pipe(
          take(1),
          map(() => {
            return SettingsActions.success();
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  updateManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.UPDATE_MANAGER),
      mergeMap(({ manager }) =>
        this.managerService.updateManager(manager).pipe(
          take(1),
          map((response) => {
            return SettingsActions.updateManagerSuccess({ response });
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  removeManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.REMOVE_MANAGER),
      mergeMap(({ manager }) =>
        this.managerService.deleteManager(manager).pipe(
          take(1),
          map((response) => {
            return SettingsActions.removeManagerSuccess({ response });
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  createManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.CREATE_MANAGER),
      mergeMap(({ manager }) =>
        this.managerService.createManager(manager).pipe(
          take(1),
          map((response) => {
            return SettingsActions.createManagerSuccess({ response });
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  createBranch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.CREATE_BRANCH),
      mergeMap(({ branch }) =>
        this.branchService.createBranch(branch).pipe(
          take(1),
          map((response) => {
            return SettingsActions.createBranchSuccess({ response });
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  updateBranch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.UPDATE_BRANCH),
      mergeMap(({ branch }) =>
        this.branchService.updateBranch(branch).pipe(
          take(1),
          map((response) => {
            return SettingsActions.updateBranchSuccess({ response });
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  removeBranch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.REMOVE_BRANCH),
      mergeMap(({ branch }) =>
        this.branchService.deleteBranch(branch).pipe(
          take(1),
          map((response) => {
            return SettingsActions.removeBranchSuccess({ response });
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  createBuildingRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.CREATE_BUILDING_ROOM),
      mergeMap(({ room, branchId, buildingId }) =>
        this.fsService.addNewBuildingRoom(room, branchId, buildingId).pipe(
          take(1),
          map(() => {
            return SettingsActions.success();
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  updateBuildingRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.UPDATE_BUILDING_ROOM),
      mergeMap(({ room, branchId, buildingId }) =>
        this.fsService.updateBuildingRoom(room, branchId, buildingId).pipe(
          take(1),
          map(() => {
            return SettingsActions.success();
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  removeBuildingRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.REMOVE_BUILDING_ROOM),
      mergeMap(({ roomId, branchId, buildingId }) =>
        this.fsService.deleteBuildingRoom(roomId, branchId, buildingId).pipe(
          take(1),
          map(() => {
            return SettingsActions.success();
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  createBuilding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.CREATE_BUILDING),
      mergeMap(({ building }) =>
        this.fsService.addNewBuilding(building).pipe(
          take(1),
          map(() => {
            return SettingsActions.success();
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  updateBuilding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.UPDATE_BUILDING),
      mergeMap(({ building }) =>
        this.fsService.updateBuilding(building).pipe(
          take(1),
          map(() => {
            return SettingsActions.success();
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  removeBuilding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.REMOVE_BUILDING),
      mergeMap(({ building }) =>
        this.fsService.deleteBuilding(building).pipe(
          take(1),
          map(() => {
            return SettingsActions.success();
          }),
          catchError((err) => of(SettingsActions.error({ error: err.message })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private managerService: ManagerService,
    private agentService: AgentService,
    private branchService: BranchService,
    private fsService: FirestoreService,
    private rtdbService: RTDBService,
    private global: GlobalDataService
  ) {}
}
