import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';

import { Agent } from '@models/v2/agent.model';
import { Activity } from '@models/v2/activity.model';
import { OrderDetails, RealTimeOrder } from '@models/data/order.model';
import { Location } from '@models/v2/location.model';

import { RTDBService } from '@firebase/rtdb.service';
import { FirestoreService } from '@firebase/firestore.service';

import * as DeliveryActions from './delivery.actions';
import { GlobalDataService } from '@shared/global.shared.service';
import { MapService } from 'src/app/services/map/map.service';
import { RealTimeDelivery } from '@models/v2/delivery.model';

@Injectable()
export class DeliveryEffects {
  getRealTimeRiders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.GET_REALTIME_AGENTS),
      mergeMap(({ selectedBranch }) =>
        this.rtdbService.listenToRealTimeAgents(selectedBranch).pipe(
          takeUntil(this.global.RealTimeAgentNotifier$),
          map((agents: Agent[]) => {
            return DeliveryActions.setRealTimeAgents({ agents });
          }),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  getSelectedBranchLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.GET_SELECTED_BRANCH_LOCATION),
      mergeMap(({ selectedBranch }) =>
        this.rtdbService.getSelectedBranchLocation(selectedBranch).pipe(
          takeUntil(this.global.RealTimeLocationNotifier$),
          map((location: Location) => {
            return DeliveryActions.setSelectedBranchLocation({ location });
          }),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  getRealTimeDeliveries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.GET_REALTIME_DELIVERIES),
      mergeMap(({ selectedBranch, dateFilter }) =>
        this.rtdbService.listenToRealTimeDeliveries(selectedBranch, dateFilter).pipe(
          takeUntil(this.global.RealTimeDeliveriesNotifier$),
          map((deliveries: RealTimeDelivery[]) => {
            return DeliveryActions.setRealTimeDeliveries({ deliveries });
          }),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  getRealTimeOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.GET_REALTIME_ORDERS),
      mergeMap(({ selectedBranch, dateFilter }) =>
        this.rtdbService.listenToRealTimeOrders(selectedBranch, dateFilter).pipe(
          takeUntil(this.global.RealTimeOrderNotifier$),
          map((orders: RealTimeOrder[]) => {
            return DeliveryActions.setRealTimeOrders({ orders });
          }),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  getNextDayRealTimeOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.GET_NEXT_DAY_REALTIME_ORDERS),
      mergeMap(({ selectedBranch, dateFilter }) =>
        this.rtdbService.listenToRealTimeOrders(selectedBranch, dateFilter).pipe(
          takeUntil(this.global.RealTimeOrderNotifier$),
          map((orders: RealTimeOrder[]) => {
            return DeliveryActions.setNextDayRealTimeOrders({ orders });
          }),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  getMonthlyOrdersAggregation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.GET_MONTHLY_ORDERS_AGGREGATION),
      mergeMap(({ selectedBranch, dateFilter }) =>
        this.rtdbService.listenToMonthlyOrdersAggregation(selectedBranch, dateFilter).pipe(
          take(1),
          map((aggregation: any) => {
            return DeliveryActions.setMonthlyOrdersAggregation({ aggregation });
          }),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  getMonthlyOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.GET_MONTHLY_ORDERS),
      mergeMap(({ selectedBranch, dateFilter }) =>
        this.rtdbService.listenToMonthlyRealTimeOrders(selectedBranch, dateFilter).pipe(
          take(1),
          map((orders: any) => {
            return DeliveryActions.setMonthlyRealTimeOrders({ orders });
          }),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  getOrderDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.GET_ORDER_DETAILS),
      switchMap(({ id, branchId }) =>
        this.fsService.getOrderDetails(id, branchId).pipe(
          takeUntil(this.global.RealTimeOrderNotifier$),
          switchMap((orderDetails: OrderDetails) => [DeliveryActions.setOrderDetails({ orderDetails })]),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  getAdvanceOrderDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.GET_ADVANCE_ORDERS),
      mergeMap(({ selectedBranch }) =>
        this.fsService.getAdvanceOrderDetails(selectedBranch).pipe(
          map((orders: any) => {
            return DeliveryActions.setAdvanceOrders({ orders });
          }),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  changeDispatchTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.SET_SELECTED_ADVANCE_ORDERS_DISPATCH_TIME),
      switchMap(({ advanceOrder, dispatchTime }) =>
        this.fsService.updateAdvanceOrderDispatchTime(advanceOrder, dispatchTime).pipe(
          map(() => {
            return DeliveryActions.success();
          }),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  getOrderActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.GET_ORDER_ACTIVITIES),
      mergeMap(({ id }) =>
        this.fsService.getOrderActivities(id).pipe(
          take(1),
          map((_activities: any) => {
            const activities: Activity[] = _activities.a;
            return DeliveryActions.setSelectedOrderActivities({ activities });
          }),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  getAgentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.GET_AGENT_DETAILS),
      switchMap(({ id }) =>
        this.fsService.getAgentDetails(id).pipe(
          take(1),
          switchMap((agentDetails: OrderDetails) => [DeliveryActions.setAgentDetails({ agentDetails })]),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  getAgentActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.GET_AGENT_ACTIVITIES),
      mergeMap(({ id }) =>
        this.fsService.getAgentActivities(id).pipe(
          take(1),
          map((_activities: any) => {
            const activities: Activity[] = _activities.a;
            return DeliveryActions.setSelectedAgentActivities({ activities });
          }),
          catchError((err) => of(DeliveryActions.error({ error: err.message })))
        )
      )
    )
  );

  updateOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.UPDATE_ORDER_STATUS),
      mergeMap(({ branchId, merchantId, orderDetails, orderStatus }) =>
        this.rtdbService.updateOrderStatus(branchId, merchantId, orderDetails, orderStatus).pipe(
          map((result) => {
            return DeliveryActions.updateOrderStatusSuccess({ result: result.data });
          }),
          catchError((err) => of(DeliveryActions.updateOrderStatusError({ error: err })))
        )
      )
    )
  );

  updateAgentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.UPDATE_AGENT_STATUS),
      mergeMap(({ branchId, merchantId, agentId, status }) =>
        this.rtdbService.updateAgentStatus(branchId, merchantId, agentId, status).pipe(
          take(1),
          map((result) => {
            return DeliveryActions.updateAgentStatusSuccess({
              result: result.data,
            });
          }),
          catchError((err) =>
            of(
              DeliveryActions.updateAgentStatusError({
                error: err.message,
              })
            )
          )
        )
      )
    )
  );

  updateDeliveryAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.UPDATE_DELIVERY_ADDRESS),
      mergeMap(({ orderDetails, selectedPlace, manager }) =>
        this.mapService.updateDeliveryAddress(orderDetails, selectedPlace, manager).pipe(
          take(1),
          map((result) => {
            return DeliveryActions.updateDeliveryAddressSuccess({
              result: result.data,
            });
          }),
          catchError((err) =>
            of(
              DeliveryActions.updateDeliveryAddressError({
                error: err.message,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private rtdbService: RTDBService,
    private fsService: FirestoreService,
    private global: GlobalDataService,
    private mapService: MapService
  ) {}
}
