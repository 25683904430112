import { Injectable } from '@angular/core';

import { INTEGRATION } from 'src/app/core/constants/integration.constants';
import { WhiteLabelTask, Task } from 'src/app/models/integration/whitelabel.model';
import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService {
  private whitelabelFunctions = this.cloudFunction.get(INTEGRATION.COURIER.WHITELABEL);

  constructor(private cloudFunction: CloudFunctionsService) {}

  // ASSIGN
  async assign(agentId: string, branchId: string, orderDetails: any[], merchantId: any) {
    const task: Task[] = orderDetails.map((order) => ({
      id: order.id,
      co: INTEGRATION.COURIER.WHITELABEL,
    }));
    const data: WhiteLabelTask = {
      merchantId,
      branchId,
      agentId,
      task,
    };
    return this.whitelabelFunctions.createOrder(data);
  }

  // QUOTE
  async quote(orderDetails: any) {
    const whitelabel = {
      loading: false,
      price: (orderDetails[0]?.totalPrice - orderDetails[0]?.subTotalPrice).toFixed(2),
    };
    return whitelabel;
  }
}
