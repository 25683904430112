import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as firebase from 'firebase/app';
import { collectionData, docData } from 'rxfire/firestore';
import { from } from 'rxjs';

// Services
import { RTDBService } from './rtdb.service';

// Models & Constants
import { RTDB_CONSTANTS } from 'src/app/core/constants/rtdb.constants';
import { Branches } from 'src/app/models/data/branch.model';
import { Merchant } from 'src/app/models/data/merchant.model';

// State
import { Store } from '@ngrx/store';
import { StoreRootState } from '../../state/state.reducers';
import * as AuthActions from 'src/app/state/auth/auth.actions';
import * as fromAuth from 'src/app/state/auth/auth.selector';
import * as moment from 'moment';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  private fs = firebase.firestore();
  private MERCHANTS = RTDB_CONSTANTS.MERCHANTS;
  private AGENTS = RTDB_CONSTANTS.ORGS.AGENTS;
  private merchantUid: string;
  private branches: Branches;
  private hqUid: string;
  private alarmGroups;

  constructor(private store: Store<StoreRootState>, private rtdbService: RTDBService) {
    this.getUser();
  }

  getUser() {
    this.store.select(fromAuth.selectUser).subscribe((user: Merchant) => {
      if (user) {
        this.merchantUid = user.role === 'owner' ? user?.uid : user?.ownerId;
        this.branches = user?.branches;
        this.hqUid = user?.hq;
        this.alarmGroups = user?.alarmGroups;
        const selectedBranch = this.branches[this.hqUid];
        this.store.dispatch(AuthActions.setMerchantSelectedBranch({ merchantSelectedBranch: selectedBranch }));
        this.store.dispatch(AuthActions.setSelectedBranch({ selectedBranch }));
      }
    });
  }

  getMerchantPath() {
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}`;
  }

  getAgentPath(agent) {
    return `agents/${agent}`;
  }

  getAgentActivitiesPath(agent, date) {
    return `agents/${agent}/activities/${date}`;
  }

  getManagersPath() {
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.MANAGERS}`;
  }

  getBranchesPath() {
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BRANCHES}`;
  }
  getBuildingsPath() {
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BUILDINGS}`;
  }

  getBuildingRoomsPath(buildingId) {
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BUILDINGS}/${buildingId}/rooms`;
  }

  getOrderPath(branchUid: string, orderId: string) {
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BRANCHES}/${branchUid}/${this.MERCHANTS.ORDERS}/${orderId}`;
  }

  getAdvanceOrderPath() {
    return `${this.MERCHANTS.ADVANCEORDERS}`;
  }

  getDeliveryPath(branchUid: string, deliveryId: string) {
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BRANCHES}/${branchUid}/${this.MERCHANTS.DELIVERIES}/${deliveryId}`;
  }

  getAgentsPath() {
    return `${this.AGENTS}`;
  }

  getManagersDetails() {
    const path = this.getManagersPath();
    const ref = this.fs.collection(path).where('removed', '==', false).where('role', '==', 'manager');
    return collectionData(ref, '');
  }

  getBuildingManagersDetails() {
    const path = this.getManagersPath();
    const ref = this.fs.collection(path).where('removed', '==', false).where('role', '==', 'buildingManager');
    return collectionData(ref, '');
  }

  getBranchesDetails() {
    const path = this.getBranchesPath();
    const ref = this.fs.collection(path);
    return collectionData(ref, '');
  }

  getAgentsDetails(branchId: any) {
    const path = this.getAgentsPath();
    const ref = this.fs.collection(path).where('branchId', '==', branchId);
    return collectionData(ref, '');
  }

  getDevices(buildingId) {
    const ref = this.fs
      .collection('devices')
      .where('merchantId', '==', this.merchantUid)
      .where('buildingId', '==', buildingId);
    return collectionData(ref, '');
  }

  getBuildingDetails(branchId) {
    const path = this.getBuildingsPath();
    const ref = this.fs.collection(path).where('removed', '==', false).where('branchId', '==', branchId);
    return collectionData(ref, '');
  }

  getBuildingRoomDetails(branchId, buildingId) {
    const path = this.getBuildingRoomsPath(buildingId);
    const ref = this.fs.collection(path).where('removed', '==', false);
    return collectionData(ref, '');
  }

  getOrderDetails(orderId: string, branchUid: string) {
    const branchUid$ = branchUid ? branchUid : this.hqUid;
    const path = this.getOrderPath(branchUid$, orderId);
    const ref = this.fs.doc(path);
    return docData(ref);
  }

  getAdvanceOrderDetails(branchUid: string) {
    const path = this.getAdvanceOrderPath();
    const ref = this.fs.collection(path).where('branchId', '==', branchUid);
    return collectionData(ref);
  }

  updateAdvanceOrderDispatchTime(advanceOrder: any, dispatchTime: any) {
    const path = this.getAdvanceOrderPath();
    const ref = this.fs.collection(path).doc(advanceOrder?.id);
    advanceOrder = {
      ...advanceOrder,
      deliverTime: firebase.firestore.Timestamp.fromDate(dispatchTime),
    };
    return from(ref.set(advanceOrder, { merge: true }));
  }

  getAgentDetails(agentId: string) {
    const path = this.getAgentPath(agentId);
    const ref = this.fs.doc(path);
    return docData(ref);
  }

  setDeliveryDetails(details: any, deliveryId: string, branchUid: string) {
    const branchUid$ = branchUid ? branchUid : this.hqUid;
    const path = this.getDeliveryPath(branchUid$, deliveryId);
    const ref = this.fs.doc(path);
    ref.set(details);
    return docData(ref);
  }

  updateOrderDetails(details: any, orderId: string, branchUid: string) {
    const branchUid$ = branchUid ? branchUid : this.hqUid;
    const path = this.getOrderPath(branchUid$, orderId);
    const ref = this.fs.doc(path);
    ref.update(details);
    return docData(ref);
  }

  updateMerchantProfile(profile) {
    this.fs.doc(`${this.MERCHANTS.ROOT}/${this.merchantUid}`).set(
      {
        profile,
      },
      { merge: true }
    );
  }

  addNewBranch(branchInfo) {
    const path = this.getBranchesPath();
    return from(
      this.fs
        .collection(path)
        .add(branchInfo)
        .then((docRef) => {
          this.fs.doc(`${this.MERCHANTS.ROOT}/${this.merchantUid}`).set(
            {
              branches: {
                [docRef.id]: {
                  name: branchInfo.name,
                  id: docRef.id,
                  franchise: branchInfo.franchise,
                },
              },
            },
            { merge: true }
          );
          this.rtdbService.addNewBranch(docRef, branchInfo.name);
        })
    );
  }

  updateBranch(branchInfo) {
    const path = this.getBranchesPath();
    const batch = this.fs.batch();
    const merchantPath = this.fs.doc(this.getMerchantPath());
    const merchantBranchInfo = this.fs.collection(path).doc(branchInfo.uid);

    batch.update(merchantBranchInfo, branchInfo);
    batch.set(
      merchantPath,
      {
        branches: {
          [branchInfo.uid]: {
            name: branchInfo.name,
            id: branchInfo.uid,
            franchise: branchInfo.franchise,
          },
        },
      },
      { merge: true }
    );

    return from(
      batch.commit().then(() => {
        this.rtdbService.updateBranch(branchInfo);
      })
    );
  }

  deleteBranch(branchInfo) {
    const path = this.getBranchesPath();
    const batch = this.fs.batch();
    const merchantPath = this.fs.doc(this.getMerchantPath());
    const merchantBranchInfo = this.fs.collection(path).doc(branchInfo.id);

    batch.update(merchantBranchInfo, { removed: true });
    batch.set(
      merchantPath,
      {
        branches: {
          [branchInfo.id]: firebase.firestore.FieldValue.delete(),
        },
      },
      { merge: true }
    );

    return from(
      batch.commit().then(() => {
        this.rtdbService.deleteBranch(branchInfo);
      })
    );
  }

  updateManager(managerInfo: any) {
    const path = this.getManagersPath();
    const batch = this.fs.batch();

    const merchantManagerProfile = this.fs.collection(path).doc(managerInfo.uid);
    batch.update(merchantManagerProfile, {
      branches: managerInfo.branches,
      hq: managerInfo.hq,
    });
    batch.set(
      merchantManagerProfile,
      {
        profile: managerInfo.profile,
      },
      { merge: true }
    );
    const managerProfile = this.fs.doc(`${this.MERCHANTS.ROOT}/${managerInfo.uid}`);
    batch.update(managerProfile, {
      branches: managerInfo.branches,
      hq: managerInfo.hq,
    });
    batch.set(
      managerProfile,
      {
        profile: managerInfo.profile,
      },
      { merge: true }
    );
    return from(batch.commit());
  }

  updateAgent(agentInfo: any) {
    const path = this.getAgentsPath();
    const agent = this.fs.collection(path);
    const agentId = agentInfo.uid;
    return from(
      agent.doc(agentId).set(
        {
          uid: agentInfo.uid,
          merchantId: agentInfo.merchantId,
          branchId: agentInfo.branchId,
          fname: agentInfo.fname,
          lname: agentInfo.lname,
          transportType: agentInfo.transportType[0].id,
          phone: agentInfo.phone,
          transportDescription: agentInfo.transportDescription,
          licensePlate: agentInfo.licensePlate,
          vehicleColor: agentInfo.vehicleColor,
        },
        { merge: true }
      )
    );
  }

  deleteManager(managerInfo: any) {
    const path = this.getManagersPath();
    const ref = this.fs.collection(path).doc(managerInfo.uid);
    return from(ref.update({ removed: true }));
  }

  addNewBuildingRoom(roomInfo, branchId, buildingId) {
    const path = this.getBuildingRoomsPath(buildingId);
    return from(
      this.fs
        .collection(path)
        .add(roomInfo)
        .then((docRef) => {
          this.fs.collection(path).doc(docRef.id).update({ id: docRef.id });
        })
    );
  }

  updateBuildingRoom(roomInfo, branchId, buildingId) {
    const path = this.getBuildingRoomsPath(buildingId);
    const merchantBuildingRoomInfo = this.fs.collection(path).doc(roomInfo.id);

    return from(merchantBuildingRoomInfo.update(roomInfo));
  }

  deleteBuildingRoom(roomId, branchId, buildingId) {
    const path = this.getBuildingRoomsPath(buildingId);
    const merchantBuildingRoomInfo = this.fs.collection(path).doc(roomId);

    return from(merchantBuildingRoomInfo.update({ removed: true }));
  }

  addNewBuilding(buildingInfo) {
    const path = this.getBuildingsPath();
    return from(
      this.fs
        .collection(path)
        .add(buildingInfo)
        .then((docRef) => {
          this.fs.collection(path).doc(docRef.id).update({ id: docRef.id });
        })
    );
  }

  updateBuilding(building) {
    const path = this.getBuildingsPath();
    const merchantBuildingInfo = this.fs.collection(path).doc(building.uid);

    return from(merchantBuildingInfo.update(building));
  }

  deleteBuilding(building) {
    const path = this.getBuildingsPath();
    const merchantBuildingInfo = this.fs.collection(path).doc(building.id);
    return from(merchantBuildingInfo.update({ removed: true }));
  }

  sendEmail(branchId, buildingId, roomId) {
    const manager = this.alarmGroups[branchId].manager;
    const buildingManager = this.alarmGroups[branchId].buildings[buildingId].buildingManager;
    const servicePeople = this.alarmGroups[branchId].buildings[buildingId].rooms[roomId].servicePeople;
    this.fs.collection('mail').add({
      to: [manager, buildingManager, servicePeople],
      message: {
        subject: 'Hello from Firebase!',
        html: 'This is an <code>HTML</code> email body.',
      },
    });
  }

  // Get order activities
  getOrderActivities(orderId: string) {
    const ref = this.fs.collection('activities').doc(orderId);
    return docData(ref);
  }

  // Get agent activities
  getAgentActivities(agentId: string) {
    const tz = 'Asia/Manila';
    const lang = 'en-US';
    moment.locale(lang);
    moment.tz.setDefault(tz);
    const day = moment().format('MMDDYYYY');
    const path = this.getAgentActivitiesPath(agentId, day);
    const ref = this.fs.doc(path);
    return docData(ref);
  }
}
