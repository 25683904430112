import { Component, Input, Output, EventEmitter } from '@angular/core';

import { RealTimeOrder } from '@models/data/order.model';

import { RTDB_CONSTANTS } from '@constants/rtdb.constants';
import { STATUS_MAP_DISPLAY_STR } from '@constants/dashboard.constants';

import { Store } from '@ngrx/store';

import * as DeliveryActions from '@state/dashboard/delivery/delivery.actions';

import * as Time from 'src/app/core/utils/time';

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss'],
})
export class OrderCardComponent {
  public PAYMENT_STATUS = RTDB_CONSTANTS.PAYMENT_STATUS;
  public RETRY_STATUS = RTDB_CONSTANTS.RETRY;
  public PAYMENT_STATUS_MAP = RTDB_CONSTANTS.PAYMENT_STATUS_MAP;
  public PAYMENT_METHOD_MAP = RTDB_CONSTANTS.PAYMENT_METHOD_MAP;
  public PAYMENT_METHOD = RTDB_CONSTANTS.PAYMENT_METHOD;
  public ORDER_STATUS = RTDB_CONSTANTS.STATUS_INT;
  public STATUS_MAP_STR = RTDB_CONSTANTS.STATUS_MAP_STR;
  public ORDER_STATUS_DISPLAY_MAP = STATUS_MAP_DISPLAY_STR;
  public searchTask: string;
  public today = new Date(Time.moment$().format(Time.defaultFormat));
  public moment = Time.moment$;

  agentStatusMap = ['Available', 'Busy', 'Busy', 'Offline'];
  agentStatusMapBadge = ['success', 'success', 'info', 'default'];
  paymentStatusBadgeMap = ['info', 'success', 'danger', 'warning'];

  @Input() featuresSubscriptions: string[];
  @Input() selectedRealTimeOrder: RealTimeOrder;
  @Input() availableAgents = [];
  @Input() ordersQue;
  @Input() orders;
  @Input() status;
  @Input() toggle;
  @Input() isMultiDropOff;
  @Input() selectedSorting;

  @Output() assignAgent$ = new EventEmitter<RealTimeOrder>();
  @Output() openOrderNav$ = new EventEmitter<RealTimeOrder>();
  @Output() isAllSelected$ = new EventEmitter();

  constructor(private store: Store) {}

  trackByFn(index: any, item: any) {
    return item;
  }

  displayAssignAgentButton(status: number) {
    return status === this.PAYMENT_STATUS.PAID;
  }

  checkSubscription(subscription: string) {
    return this.featuresSubscriptions.indexOf(subscription) !== -1;
  }

  insertToQueue(selectedRealTimeOrder: RealTimeOrder) {
    if (!this.availableAgents?.length) {
      this.store.dispatch(DeliveryActions.insertOrderToQueue({ selectedRealTimeOrder }));
    } else {
      this.store.dispatch(DeliveryActions.assignQueuedOrder({ assignQueuedOrder: selectedRealTimeOrder }));
    }
  }

  queueCheck(realTimeOrder: RealTimeOrder) {
    if (
      realTimeOrder?.r > this.RETRY_STATUS.ZERO &&
      realTimeOrder?.p === this.PAYMENT_STATUS.PAID &&
      realTimeOrder?.s !== this.ORDER_STATUS.FAILED
    ) {
      return true;
    } else {
      const index = this.ordersQue.findIndex((order: any) => order.id === realTimeOrder.id);
      return index;
    }
  }

  openOrderNav(selectedRealTimeOrder: RealTimeOrder) {
    this.openOrderNav$.emit(selectedRealTimeOrder);
  }

  assignAgentModal(selectedRealTimeOrder: RealTimeOrder) {
    this.assignAgent$.emit(selectedRealTimeOrder);
  }

  isAllSelected(event: any) {
    this.isAllSelected$.emit({ event });
  }
}
