import { Injectable } from '@angular/core';
import { Merchant } from '@models/v2/merchant.model';
import { from } from 'rxjs';
import { Task } from 'src/app/models/v2/task.model';
import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private paymentFunctions = this.cloudFunction.payment();

  constructor(private cloudFunction: CloudFunctionsService) {}

  createPayment(order: Task) {
    return from(this.paymentFunctions.create({ order }) as Promise<any>);
  }

  updatePayment(user: Merchant, id: string, fulfillAt: string, status: number, timezone: string, autoAllocate: number) {
    return from(this.paymentFunctions.update({ user, id, fulfillAt, status, timezone, autoAllocate }) as Promise<any>);
  }
}
