import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { getAgentMarkerCluster } from 'src/app/core/utils/url';

// Constants
import { IMAGE_EXTENSION } from '@constants/map.constants';
import { SUBSCRIPTIONS } from '@constants/dashboard.constants';

import { GlobalDataService } from '@shared/global.shared.service';
import { from } from 'rxjs';
import { OrderDetails } from '@models/data/order.model';
import { CloudFunctionsService } from '@firebase/cloud.functions.services';
import { Merchant } from '@models/v2/merchant.model';
import { DeviceService } from '@utils/device.service';

export declare const google: any;

@Injectable({
  providedIn: 'root',
})
export class MapService {
  public IMAGE_EXTENSION = IMAGE_EXTENSION.SVG;

  public leftLabel: string;
  public rightLabel: string;

  public leftOpen = true;
  public rightOpen = false;
  public map: any;
  public directionsService: any;
  public directionsDisplay: any;
  agentMarkers = [];
  public markerCluster = null;
  public featuresSubscriptions: string[];

  public minWidth: string;
  public marginLeft: string;

  private locationFunctions = this.cloudFunction.location();
  origins = [];
  destinations = [];
  infoWindow: any;

  constructor(
    private global: GlobalDataService,
    private cloudFunction: CloudFunctionsService,
    private deviceService: DeviceService
  ) {}
  isMobile = false;

  initializeGoogleMap() {
    const found = this.global.featuresSubscriptions.find((subscription) => subscription === SUBSCRIPTIONS.whitelabel);
    this.map = document.getElementById('map');

    this.minWidth = '300px';
    this.marginLeft = 'clamp(300px, 20vw, 20vw)';

    if (this.deviceService.IsMobile() || this.deviceService.isTablet()) {
      this.isMobile = true;
      document.getElementById('mySidenavLeft').style.width = '300px';
    }

    const lat = 14.577936;
    const lng = 121.008389;

    const latlng = new google.maps.LatLng(lat, lng);
    const styles = [
      {
        featureType: 'administrative',
        elementType: 'all',
        stylers: [
          {
            saturation: '-100',
          },
        ],
      },
      {
        featureType: 'administrative.province',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 65,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: '50',
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [
          {
            saturation: '-100',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'all',
        stylers: [
          {
            lightness: '30',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'all',
        stylers: [
          {
            lightness: '40',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
          {
            saturation: -100,
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            hue: '#ffff00',
          },
          {
            lightness: -25,
          },
          {
            saturation: -97,
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
          {
            lightness: -25,
          },
          {
            saturation: -100,
          },
        ],
      },
    ];
    const mapOptions = {
      zoom: 16,
      scrollwheel: true,
      center: latlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_CENTER,
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      scaleControl: true,
      streetViewControl: false,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_RIGHT,
      },
      styles,
    };
    this.map = new google.maps.Map(this.map, mapOptions);
    const navControlDiv = document.createElement('div');
    const navControlDivRight = document.createElement('div');
    const centerControlDiv = document.createElement('div');

    if (found) {
      this.navControlRight(navControlDivRight);
      this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(navControlDivRight);
    }

    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
    this.navControl(navControlDiv);
    this.map.controls[google.maps.ControlPosition.LEFT_CENTER].push(navControlDiv);

    this.directionsService = new google.maps.DirectionsService();

    this.infoWindow = new google.maps.InfoWindow();
  }

  navControl(controlDiv: Element) {
    // Set CSS for the control border.
    const controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#717171';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginBottom = '22px';
    controlUI.style.textAlign = 'center';
    controlUI.title = 'Click to recenter the map';
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement('div');
    controlText.style.color = '#fff';
    controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlText.style.lineHeight = '42px';
    controlText.style.paddingLeft = '10px';
    controlText.style.paddingRight = '10px';
    controlText.innerHTML = '<i class="tim-icons icon-minimal-left"></i>';
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener('click', () => {
      this.leftToggle();
      controlText.innerHTML = this.leftLabel;
    });
  }

  navControlRight(controlDiv: Element) {
    // Set CSS for the control border.
    const controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#717171';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginBottom = '22px';
    controlUI.style.textAlign = 'center';
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement('div');
    controlText.style.color = '#fff';
    controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlText.style.lineHeight = '42px';
    controlText.style.paddingLeft = '10px';
    controlText.style.paddingRight = '10px';
    controlText.innerHTML = '<i class="tim-icons icon-minimal-left"></i>';
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener('click', () => {
      this.rightToggle();
      controlText.innerHTML = this.rightLabel;
    });
  }

  leftToggle() {
    if (this.leftOpen) {
      this.closeLeftNav();
      this.minWidth = '0px';
      this.marginLeft = '0px';
      this.leftLabel = '<i class="tim-icons icon-minimal-right"></i>';
    } else {
      this.openLeftNav();
      this.minWidth = '300px';
      this.marginLeft = 'clamp(300px, 20vw, 20vw)';
      this.leftLabel = '<i class="tim-icons icon-minimal-left"></i>';
    }
    this.leftOpen = !this.leftOpen;
  }

  rightToggle() {
    if (this.rightOpen) {
      this.closeRightNav();
      this.rightLabel = '<i class="tim-icons icon-minimal-left"></i>';
    } else {
      this.openRightNav();
      this.rightLabel = '<i class="tim-icons icon-minimal-right"></i>';
    }
    this.rightOpen = !this.rightOpen;
  }

  openLeftNav() {
    document.getElementById('mySidenavLeft').style.width = this.isMobile ? '300px' : '20vw';
    document.getElementById('main').style.marginLeft = this.isMobile ? '300px' : '20vw';
    document.getElementById('main').style.transition = 'margin-left .5s';
  }

  closeLeftNav() {
    document.getElementById('mySidenavLeft').style.width = '0';
    document.getElementById('main').style.marginLeft = '0';
    document.getElementById('main').style.transition = 'margin-left .5s';
  }

  openRightNav() {
    document.getElementById('mySidenavRight').style.width = '20vw';
    document.getElementById('main').style.marginRight = '20vw';
    document.getElementById('main').style.transition = 'margin-right .5s';
  }

  closeRightNav() {
    document.getElementById('mySidenavRight').style.width = '0';
    document.getElementById('main').style.marginRight = '0';
    document.getElementById('main').style.transition = 'margin-right .5s';
  }

  showAgentMarker(lat: number, lng: number) {
    this.clearMarkers();
    const latLng = {
      lat: lat,
      lng: lng,
    };
    if (!_.isEmpty(latLng)) {
      const map = this.map;
      const marker = new google.maps.Marker({
        animation: google.maps.Animation.DROP,
        position: latLng,
        map,
        zIndex: 100,
      });
      this.map.setZoom(20);
      this.map.setCenter(latLng);
      this.map.panTo(latLng);
      this.agentMarkers.push(marker);
    }
  }

  createClusterMarker(markers: any[]) {
    if (this.markerCluster) {
      this.markerCluster.clearMarkers();
    }
    const clusterOptions = {
      imagePath: getAgentMarkerCluster(),
      zoomOnClick: true,
      imageExtension: this.IMAGE_EXTENSION,
      gridSize: 40,
    };
    this.markerCluster = new MarkerClusterer(this.map, markers, clusterOptions);
  }

  setMapOnAll(map: any | null) {
    for (let i = 0; i < this.agentMarkers.length; i++) {
      this.agentMarkers[i].setMap(map);
    }
  }

  clearMarkers() {
    this.setMapOnAll(null);
    this.agentMarkers = [];
  }

  fitBounds(markers: any[]) {
    const bounds = new google.maps.LatLngBounds();
    if (markers.length) {
      markers.forEach((marker) => bounds.extend(marker.getPosition()));
      this.map.fitBounds(bounds);
    }
  }

  updateDeliveryAddress(orderDetails: OrderDetails, selectedPlace, manager: Merchant) {
    return from(this.locationFunctions.update({ orderDetails, selectedPlace, manager }) as Promise<any>);
  }

  calculateAndDisplayRoute(origin, destination) {
    const directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true });
    directionsDisplay.setMap(this.map);
    const me = this;
    this.directionsService.route(
      {
        origin,
        destination,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
      },
      function (response, status) {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
          const center = response.routes[0].overview_path[Math.floor(response.routes[0].overview_path.length / 2)];
          const infowindow = new google.maps.InfoWindow();
          infowindow.setPosition(center);
          infowindow.setContent(
            response.routes[0].legs[0].duration.text + '<br>' + response.routes[0].legs[0].distance.text
          );
          infowindow.open(me.map);
        } else {
          window.alert('Directions request failed due to ' + status);
        }
      }
    );
  }

  getDistance(origin, destination) {
    if (!this.origins.includes(origin)) {
      this.origins.push(origin);
    }

    if (!this.destinations.includes(destination)) {
      this.destinations.push(destination);
    }
    // Find the distance
    const distanceService = new google.maps.DistanceMatrixService();
    distanceService.getDistanceMatrix(
      {
        origins: this.origins,
        destinations: this.destinations,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        durationInTraffic: true,
        avoidHighways: false,
        avoidTolls: false,
      },
      function (response, status) {
        if (status !== google.maps.DistanceMatrixStatus.OK) {
          console.log('Error:', status);
        } else {
          console.log('distance', response);
        }
      }
    );
  }
}
