import { Injectable } from '@angular/core';
import { RTDB_CONSTANTS } from '@constants/rtdb.constants';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

const defaults = {
  timeOut: 5000,
  closeButton: true,
  enableHtml: true,
  positionClass: 'toast-bottom-right',
};

const deliveryStatus = {
  timeOut: 5000,
  closeButton: true,
  enableHtml: true,
  positionClass: 'toast-top-right',
};

type TOAST_TYPE = 'default' | 'info' | 'success' | 'warning' | 'danger';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastr: ToastrService, private translate: TranslateService) {}

  getToastTranslation(translation: string) {
    let stringTranslation: string;
    this.translate
      .get(`DASHBOARD.toast.${translation}`)
      .subscribe((result: string) => {
        stringTranslation = result;
      })
      .unsubscribe();
    return stringTranslation;
  }

  showDeliveryToast(title, msg, type: TOAST_TYPE, notifType: any) {
    this.toastr.show(`<span class="tim-icons icon-bell-55"></span> ${msg}`, title, {
      ...notifType,
      toastClass: `alert alert-${type} alert-with-icon`,
    });
  }

  showToast(msg: string, type: TOAST_TYPE, notifType: any) {
    this.toastr.show('<span class="tim-icons icon-bell-55"></span>', msg, {
      ...notifType,
      toastClass: `alert alert-${type} alert-with-icon`,
    });
  }

  showDefault(msg: string) {
    this.showToast(msg, 'default', defaults);
  }

  showInfo(msg: string) {
    this.showToast(msg, 'info', defaults);
  }

  showSuccess(msg: string) {
    this.showToast(msg, 'success', defaults);
  }

  showWarning(msg: string) {
    this.showToast(msg, 'warning', defaults);
  }

  showDanger(msg: string) {
    this.showToast(msg, 'danger', defaults);
  }

  showDeliveryStatus(id: string, status: number, courier: string, statusRC) {
    const statusStr = RTDB_CONSTANTS.STATUS_MAP_STR[status];
    this.showDeliveryToast(
      `Order Id: ${id} - ${_.capitalize(courier)}`,
      this.getToastTranslation(`delivery.${statusStr}`).replace('[replace]', _.capitalize(courier)),
      statusRC[statusStr].color,
      deliveryStatus
    );
  }
}
