import { Injectable } from '@angular/core';

// Utils
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

// Constants
import { MODAL } from 'src/app/core/constants/modal.constants';
import { RTDB_CONSTANTS } from '@constants/rtdb.constants';
import { Platform } from '@models/data/platform.model';
import { updateBranch } from '../../state/settings/settings.actions';
import { User } from '@models/v2/user.model';
import { Profile } from '@models/v2/profile.model';

interface Update {
  user?: User;
  id?: string;
  agentId?: string;
  orderId?: string;
  branchId?: string;
  loading?: boolean;
  merchantId?: string;
  message?: string;
  orderStatus?: number;
  status?: number;
  success?: boolean;
  error?: string;
  customerOrderId?: string;
  orderRef?: string;
  courier?: string;
  data?: {
    name?: string;
    franchise?: boolean;
    profile?: Profile;
  };
  branch?: {
    name?: string;
    franchise?: boolean;
  };
  manager?: any;
  name?: string;
}

type Modal = 'branch' | 'agent' | 'manager';

interface SwalStatus {
  success: SweetAlertOptions;
  updating: SweetAlertOptions;
  error: SweetAlertOptions;
}

interface TransContent {
  id?: string;
  status?: string;
  courier?: string;
  next?: string;
}

const SwalMessageDefaults: SweetAlertOptions = {
  position: 'center',
  icon: 'warning',
  timer: 3000,
};

const SwalAlertDefaults: SweetAlertOptions = {
  position: 'center',
  icon: 'warning',
  timer: 10000,
};

const SwalSuccessDefaults: SweetAlertOptions = {
  position: 'center',
  icon: 'info',
  timer: 10000,
};

const SwalUpdatingDefaults: SweetAlertOptions = {
  position: 'center',
  icon: 'info',
  timer: 30000,
  didOpen: () => {
    Swal.showLoading();
  },
  allowOutsideClick: false,
  allowEnterKey: false,
  allowEscapeKey: false,
};

const SwalErrorDefaults: SweetAlertOptions = {
  position: 'center',
  icon: 'warning',
  timer: 10000,
};

const SwalConfirmDefaults: SweetAlertOptions = {
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: `Yes`,
  showLoaderOnConfirm: true,
  cancelButtonText: `No`,
};

const SwalStatus: SwalStatus = {
  success: SwalSuccessDefaults,
  updating: SwalUpdatingDefaults,
  error: SwalErrorDefaults,
};

@Injectable()
export class ModalService {
  public AGENT_STATUS_STR = RTDB_CONSTANTS.AGENT_STATUS_STR;
  public ORDER_STATUS_MAP = RTDB_CONSTANTS.STATUS_MAP_STR;
  public PAYMENT_STATUS_MAP = RTDB_CONSTANTS.PAYMENT_STATUS_MAP;

  constructor(private translate: TranslateService) {}

  createUpdateSwal(swal: SweetAlertOptions, title: string, text?: string) {
    const options = {
      ...swal,
      title,
      text,
    };
    return Swal.fire(options);
  }

  getModalTranslation(translation: string, valueParam?: any) {
    let stringTranslation: string;
    this.translate
      .get(`DASHBOARD.modal.${translation}`, { value: valueParam })
      .subscribe((result: string) => {
        stringTranslation = result;
      })
      .unsubscribe();
    return stringTranslation;
  }

  capitalize(courier) {
    if (typeof courier !== 'string') {
      return courier;
    }
    return courier.charAt(0).toUpperCase() + courier.slice(1);
  }

  initializeLoaderContent(
    update: Update,
    translation: string,
    defaults: SweetAlertOptions,
    translationContent: TransContent
  ) {
    if (update?.loading) {
      return this.createUpdateSwal(defaults, this.getModalTranslation(translation));
    } else if (update?.message && !update?.loading) {
      return this.createUpdateSwal(
        SwalSuccessDefaults,
        this.getModalTranslation(MODAL.agentUpdate.success, {
          ...translationContent,
        })
      );
    } else if (update?.error && !update?.loading) {
      return this.createUpdateSwal(defaults, this.getModalTranslation(translation));
    }
  }

  checkPinLocationMessage(selectedRealTimeOrder) {
    return this.messageModal(MODAL.pinLocation.check);
  }

  updateAgentAlert(agentUpdateStatus: Update) {
    if (agentUpdateStatus?.loading) {
      return this.createUpdateSwal(SwalUpdatingDefaults, this.getModalTranslation(MODAL.agentUpdate.updating));
    } else if (agentUpdateStatus?.message && !agentUpdateStatus?.loading) {
      return this.createUpdateSwal(
        SwalSuccessDefaults,
        this.getModalTranslation(MODAL.agentUpdate.success, {
          status: this.AGENT_STATUS_STR[agentUpdateStatus?.status],
        })
      );
    } else if (agentUpdateStatus?.error && !agentUpdateStatus?.loading) {
      return this.createUpdateSwal(SwalErrorDefaults, this.getModalTranslation(MODAL.agentUpdate.error));
    }
  }

  updateDeliveryAddressAlert(deliveryAddressUpdateStatus: Update) {
    if (deliveryAddressUpdateStatus?.loading) {
      return this.createUpdateSwal(SwalUpdatingDefaults, this.getModalTranslation(MODAL.updateAddress.updating));
    } else if (deliveryAddressUpdateStatus?.message && !deliveryAddressUpdateStatus?.loading) {
      return this.createUpdateSwal(
        SwalSuccessDefaults,
        this.getModalTranslation(MODAL.updateAddress.success, {
          status: this.AGENT_STATUS_STR[deliveryAddressUpdateStatus?.status],
        })
      );
    } else if (deliveryAddressUpdateStatus?.error && !deliveryAddressUpdateStatus?.loading) {
      return this.createUpdateSwal(SwalErrorDefaults, this.getModalTranslation(MODAL.updateAddress.error));
    }
  }

  cancelDeliveryAlert(deliveryCancelStatus: Update) {
    if (deliveryCancelStatus?.loading) {
      return this.createUpdateSwal(SwalUpdatingDefaults, this.getModalTranslation(MODAL.deliveryCancel.updating));
    } else if (deliveryCancelStatus?.message) {
      if (deliveryCancelStatus?.success) {
        return this.createUpdateSwal(
          SwalSuccessDefaults,
          this.getModalTranslation(MODAL.deliveryCancel.success, {
            id: deliveryCancelStatus.orderId,
          })
        );
      } else {
        return this.createUpdateSwal(SwalErrorDefaults, this.getModalTranslation(MODAL.deliveryCancel.error));
      }
    } else if (deliveryCancelStatus?.error && !deliveryCancelStatus?.loading) {
      return this.createUpdateSwal(SwalErrorDefaults, this.getModalTranslation(MODAL.deliveryCancel.error));
    }
  }

  updateOrderAlert(orderUpdateStatus: Update) {
    if (orderUpdateStatus?.loading) {
      return this.createUpdateSwal(SwalUpdatingDefaults, this.getModalTranslation(MODAL.orderUpdate.updating));
    } else if (orderUpdateStatus?.message) {
      return this.createUpdateSwal(
        SwalSuccessDefaults,
        this.getModalTranslation(MODAL.orderUpdate.success, {
          id: orderUpdateStatus.orderId,
          status: this.ORDER_STATUS_MAP[orderUpdateStatus?.orderStatus],
        })
      );
    } else if (orderUpdateStatus?.error && !orderUpdateStatus?.loading) {
      return this.createUpdateSwal(SwalErrorDefaults, this.getModalTranslation(MODAL.orderUpdate.error));
    }
  }

  updatePaymentAlert(paymentUpdateStatus: Update) {
    if (paymentUpdateStatus?.loading) {
      return this.createUpdateSwal(SwalUpdatingDefaults, this.getModalTranslation(MODAL.paymentUpdate.updating));
    } else if (paymentUpdateStatus?.message) {
      return this.createUpdateSwal(
        SwalSuccessDefaults,
        this.getModalTranslation(MODAL.paymentUpdate.success, {
          id: paymentUpdateStatus.id,
          status: this.PAYMENT_STATUS_MAP[paymentUpdateStatus?.status],
        })
      );
    } else if (paymentUpdateStatus?.error && !paymentUpdateStatus?.loading) {
      return this.createUpdateSwal(SwalErrorDefaults, this.getModalTranslation(MODAL.paymentUpdate.error));
    }
  }

  integrationAlert(response: Update) {
    if (response?.loading) {
      return this.createUpdateSwal(
        SwalUpdatingDefaults,
        this.getModalTranslation(MODAL.assignPlatform.updatingTitle, {
          id: response.orderId,
          courier: this.capitalize(response.name),
        }),
        this.getModalTranslation(MODAL.assignPlatform.updatingText)
      );
    } else if (response?.success) {
      return this.createUpdateSwal(
        SwalSuccessDefaults,
        this.getModalTranslation(MODAL.assignPlatform.successTitle, {
          id: response.orderId,
          courier: this.capitalize(response.courier),
        }),
        this.getModalTranslation(MODAL.assignPlatform.successText, {
          courier: this.capitalize(response.courier),
        })
      );
    } else if (!response?.success && response?.loading) {
      return this.createUpdateSwal(SwalErrorDefaults, this.getModalTranslation(MODAL.assignPlatform.error));
    }
  }

  createBranchAlert(response: Update) {
    if (response?.loading) {
      return this.createUpdateSwal(
        SwalUpdatingDefaults,
        this.getModalTranslation(MODAL.createBranch.updating, {
          id: response.branch.name,
        })
      );
    } else if (response?.success) {
      return this.createUpdateSwal(
        SwalSuccessDefaults,
        this.getModalTranslation(MODAL.createBranch.success, {
          id: response.data.name,
        })
      );
    } else if (!response?.success && response?.loading) {
      return this.createUpdateSwal(
        SwalErrorDefaults,
        this.getModalTranslation(MODAL.createBranch.error, {
          id: response.data.name,
        })
      );
    }
  }

  removeBranchAlert(response: Update) {
    if (response?.loading) {
      return this.createUpdateSwal(
        SwalUpdatingDefaults,
        this.getModalTranslation(MODAL.removeBranch.updating, {
          id: response.branch.name,
        })
      );
    } else if (response?.success) {
      return this.createUpdateSwal(
        SwalSuccessDefaults,
        this.getModalTranslation(MODAL.removeBranch.success, {
          id: response.data.name,
        })
      );
    } else if (!response?.success && response?.loading) {
      return this.createUpdateSwal(
        SwalErrorDefaults,
        this.getModalTranslation(MODAL.removeBranch.error, {
          id: response.data.name,
        })
      );
    }
  }

  updateBranchAlert(response: Update) {
    if (response?.loading) {
      return this.createUpdateSwal(
        SwalUpdatingDefaults,
        this.getModalTranslation(MODAL.updateBranch.updating, {
          id: response.branch.name,
        })
      );
    } else if (response?.success) {
      return this.createUpdateSwal(
        SwalSuccessDefaults,
        this.getModalTranslation(MODAL.updateBranch.success, {
          id: response.data.name,
        })
      );
    } else if (!response?.success && response?.loading) {
      return this.createUpdateSwal(
        SwalErrorDefaults,
        this.getModalTranslation(MODAL.updateBranch.error, {
          id: response.data.name,
        })
      );
    }
  }

  createManagerAlert(response: Update) {
    if (response?.loading) {
      return this.createUpdateSwal(
        SwalUpdatingDefaults,
        this.getModalTranslation(MODAL.createManager.updating, {
          id: `${response.manager.profile.firstname} ${response.manager.profile.lastname}`,
        })
      );
    } else if (response?.success) {
      return this.createUpdateSwal(
        SwalSuccessDefaults,
        this.getModalTranslation(MODAL.createManager.success, {
          id: response.user.displayName,
        })
      );
    } else if (!response?.success && response?.loading) {
      return this.createUpdateSwal(
        SwalErrorDefaults,
        this.getModalTranslation(MODAL.createManager.error, {
          id: response.user.displayName,
        })
      );
    }
  }

  createMerchantAlert(response: any) {
    if (response?.loading) {
      return this.createUpdateSwal(SwalUpdatingDefaults, this.getModalTranslation(MODAL.createMerchant.updating));
    } else if (response?.user) {
      return this.createUpdateSwal(SwalSuccessDefaults, this.getModalTranslation(MODAL.createMerchant.success));
    } else if (response?.codePrefix) {
      return this.createUpdateSwal(
        SwalErrorDefaults,
        this.getModalTranslation(MODAL.createMerchant.error, {
          id: response.errorInfo.message,
        })
      );
    }
  }

  updateManagerAlert(response: Update) {
    if (response?.loading) {
      return this.createUpdateSwal(
        SwalUpdatingDefaults,
        this.getModalTranslation(MODAL.updateManager.updating, {
          id: `${response.manager.profile.firstname} ${response.manager.profile.lastname}`,
        })
      );
    } else if (response?.success) {
      return this.createUpdateSwal(
        SwalSuccessDefaults,
        this.getModalTranslation(MODAL.updateManager.success, {
          id: `${response.data?.profile.firstname} ${response.data?.profile.lastname}`,
        })
      );
    } else if (!response?.success && response?.loading) {
      return this.createUpdateSwal(
        SwalErrorDefaults,
        this.getModalTranslation(MODAL.updateManager.error, {
          id: `${response.manager.profile.firstname} ${response.manager.profile.lastname}`,
        })
      );
    }
  }

  removeManagerAlert(response: Update) {
    if (response?.loading) {
      return this.createUpdateSwal(
        SwalUpdatingDefaults,
        this.getModalTranslation(MODAL.removeManager.updating, {
          id: `${response.manager.profile.firstname} ${response.manager.profile.lastname}`,
        })
      );
    } else if (response?.success) {
      return this.createUpdateSwal(
        SwalSuccessDefaults,
        this.getModalTranslation(MODAL.removeManager.success, {
          id: `${response.data?.profile.firstname} ${response.data?.profile.lastname}`,
        })
      );
    } else if (!response?.success && response?.loading) {
      return this.createUpdateSwal(
        SwalErrorDefaults,
        this.getModalTranslation(MODAL.removeManager.error, {
          id: `${response.manager.profile.firstname} ${response.manager.profile.lastname}`,
        })
      );
    }
  }

  messageModal(translation: string) {
    const options = {
      ...SwalMessageDefaults,
      title: this.getModalTranslation(translation, {}),
      allowOutsideClick: () => !Swal.isLoading(),
    };
    return Swal.fire(options);
  }

  alertModal(id: string, translation: string) {
    const options = {
      ...SwalAlertDefaults,
      title: this.getModalTranslation(translation, {
        id,
      }),
      allowOutsideClick: () => !Swal.isLoading(),
    };
    return Swal.fire(options);
  }

  confirmModal(id: string, translation: string, next?: string) {
    const options = {
      ...SwalConfirmDefaults,
      title: this.getModalTranslation(translation, {
        id,
        next,
      }),
      allowOutsideClick: () => !Swal.isLoading(),
    };
    return Swal.fire(options);
  }

  createForceDesktopViewModal() {
    return this.createUpdateSwal(
      SwalAlertDefaults,
      this.getModalTranslation(MODAL.userDevice.alert),
      this.getModalTranslation(MODAL.userDevice.text)
    );
  }
}
