import { Injectable } from '@angular/core';
import { RTDB_CONSTANTS } from '@constants/rtdb.constants';

const STATUS_MAP_CATEGORY = RTDB_CONSTANTS.STATUS_MAP_CATEGORY;
const noOfStatuses = 3;
const co2divisor = 40;

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  static statusProgressMap = {
    new: 1,
    inprogress: 2,
    finished: 3,
    removed: 0,
  };

  static themePieMap = ['#49ce4c', '#fdd125', '#faaa00', '#f98704', '#e9343a'];

  static themeStatusMap = {
    new: 'warning',
    inprogress: 'info',
    finished: 'success',
    removed: 'danger',
  };

  static themeScoreMap = ['good', 'mild', 'moderate', 'bad', 'severe'];

  static mapToStatus(statu$: number) {
    return STATUS_MAP_CATEGORY[statu$];
  }

  static computeStatusProgress(statu$: number) {
    const mapStatus = this.mapToStatus(statu$);
    return (this.statusProgressMap[mapStatus] / noOfStatuses) * 100;
  }

  static computeThemeStatusProgress(statu$: number) {
    const mapStatus = this.mapToStatus(statu$);
    return this.themeStatusMap[mapStatus];
  }

  static computeScorePie(index) {
    const level = Math.floor((100 - index) / 20);
    return this.themeScoreMap[level];
  }

  static computeThemeScorePie(index) {
    const level = Math.floor((100 - index) / 20);
    return this.themePieMap[level];
  }

  static computeScoreProgress(score$, sensor) {
    if (sensor === 'co2') {
      return score$ / co2divisor;
    } else if (sensor === 'airPressure') {
      // to  set the ratio of air pressure from (600 - 1100) to (0 - 600)
      return score$ - 600;
    } else {
      return score$;
    }
  }

  static computeThemeScoreProgress(score$) {
    return this.themeScoreMap[score$];
  }
}
