import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor() {}

  IsMobile() {
    return /Android|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
  }

  isTablet() {
    return /iPad/i.test(navigator.userAgent);
  }

  isDesktop() {
    return /webOS|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
