import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  private agentFunctions = this.cloudFunction.agent();

  constructor(private cloudFunction: CloudFunctionsService) {}

  async create(agent) {
    return this.agentFunctions.createAgent(agent);
  }
  createAgent(agent) {
    return from(this.create(agent) as Promise<any>);
  }
}
