import { createAction, props } from '@ngrx/store';

import { PageStructure } from 'src/app/models/structure/page.structure.model';

export const SET_PAGE_STRUCTURE = '[Structure] Set Page Structure';
export const RESET_PAGE_STRUCTURE = '[Structure] Reset Page Structure';

export const SUCCESS = '[Structure] Success';
export const ERROR = '[Structure] Error';

export const setPageStructure = createAction(SET_PAGE_STRUCTURE, props<{ structure: PageStructure }>());
export const reset = createAction(RESET_PAGE_STRUCTURE);

export const error = createAction(ERROR, props<{ error: Error }>());
