// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ENVIRONMENTS } from '@constants/global.constants';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  envName: ENVIRONMENTS.DEV,
  BASE_URL: 'http://localhost:4200',
  config: {
    apiKey: 'AIzaSyD17wCbIodFHzPH3ePxpChyWPzJIrCTsZE',
    authDomain: 'zippelin-dev.firebaseapp.com',
    databaseURL: 'https://zippelin-dev.firebaseio.com',
    projectId: 'zippelin-dev',
    storageBucket: 'zippelin-dev.appspot.com',
    messagingSenderId: '650959431652',
    appId: '1:650959431652:web:dd85e9f9466d33c6a6c9f1',
    measurementId: 'G-3F9W338KZ4',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
