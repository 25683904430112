import { createSelector } from '@ngrx/store';
import { Integration } from 'src/app/models/integration/integration.model';
import { Delivery } from 'src/app/models/integration/delivery.model';
import { StoreRootState } from 'src/app/state/state.reducers';
import * as IntegrationActions from '@state/integration/integration.actions';

export const selectIntegration = (state: StoreRootState) => state?.integration;

export const selectDelivery = createSelector(selectIntegration, (integration: Integration) => integration?.delivery);

export const selectDeliveryPricing = createSelector(selectDelivery, (delivery: Delivery) => delivery?.pricing);

export const selectDeliveryPlatform = createSelector(selectDelivery, (delivery: Delivery) => delivery?.selected);

export const selectOrder = createSelector(selectIntegration, (integration: Integration) => integration?.order);

export const selectPayment = createSelector(selectIntegration, (integration: Integration) => integration?.payment);

export const selectCancelOrderStatus = createSelector(
  selectIntegration,
  (integration: Integration) => integration?.cancelOrderStatus
);

export const selectAgentList = createSelector(
  selectIntegration,
  (integration: Integration) => integration?.delivery?.whiteLabel?.agents
);

export const selectWhitelabelAssignStatus = createSelector(
  selectIntegration,
  (integration: Integration) => integration?.delivery?.whiteLabel?.manual
);

export const selectWhitelabelMultiDropOffAssignStatus = createSelector(
  selectIntegration,
  (integration: Integration) => integration?.delivery?.whiteLabel?.multi
);

export const selectWhitelabelAutoAssignStatus = createSelector(
  selectIntegration,
  (integration: Integration) => integration?.delivery?.whiteLabel?.auto
);

export const selectIntegrationResponse = createSelector(selectIntegration, (integration: Integration) => {
  return integration?.response;
});
