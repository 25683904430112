import { createAction, props } from '@ngrx/store';
import { ObjectIterator } from 'lodash';

export const SET_PAGE_STRUCTURE = '[Settings] Get Page Structure';
export const GET_INTEGRATION_SETTINGS = '[Settings] Get Integration';
export const GET_FEATURE_SETTINGS = '[Settings] Get Feature';
export const GET_MANAGERS_SETTINGS = '[Settings] Get Managers';
export const GET_BRANCHES_SETTINGS = '[Settings] Get Branches';
export const GET_AGENTS_SETTINGS = '[Settings] Get Agents';

export const GET_BUILDING_MANAGER = '[Settings] Get Building Manager';
export const SET_BUILDING_MANAGER = '[Settings] Set Building Manager';

export const SET_MANAGERS_SETTINGS = '[Settings] Set Managers';
export const SET_BRANCHES_SETTINGS = '[Settings] Set Branches';
export const SET_AGENTS_SETTINGS = '[Settings] Set Agents';

export const CREATE_BUILDING_MANAGER = '[Settings] Create Building Manager';
export const UPDATE_BUILDING_MANAGER = '[Settings] Update Building Manager';
export const REMOVE_BUILDING_MANAGER = '[Settings] Remove Building Manager';

export const CREATE_MANAGER = '[Settings] Create Manager';
export const UPDATE_MANAGER = '[Settings] Update Manager';
export const REMOVE_MANAGER = '[Settings] Remove Manager';

export const CREATE_MANAGER_SUCCESS = '[Settings] Create Manager Success';
export const UPDATE_MANAGER_SUCCESS = '[Settings] Update Manager Success';
export const REMOVE_MANAGER_SUCCESS = '[Settings] Remove Manager Success';

export const CREATE_AGENT = '[Settings] Create Agent';
export const UPDATE_AGENT = '[Settings] Update Agent';
export const REMOVE_AGENT = '[Settings] Remove Agent';

export const CREATE_AGENT_SUCCESS = '[Settings] Create Agent Success';
export const UPDATE_AGENT_SUCCESS = '[Settings] Update Agent Success';

export const CREATE_BRANCH = '[Settings] Create Branch';
export const UPDATE_BRANCH = '[Settings] Update Branch';
export const REMOVE_BRANCH = '[Settings] Remove Branch';

export const CREATE_BRANCH_SUCCESS = '[Settings] Create Branch Success';
export const REMOVE_BRANCH_SUCCESS = '[Settings] Remove Branch Success';
export const UPDATE_BRANCH_SUCCESS = '[Settings] Update Branch Success';

// IOT
export const GET_BUILDINGS_SETTINGS = '[Settings] Get Buildings';
export const SET_BUILDINGS_SETTINGS = '[Settings] Set Buildings';

export const GET_ROOMS_SETTINGS = '[Settings] Get Rooms';
export const SET_ROOMS_SETTINGS = '[Settings] Set Rooms';

export const CREATE_BUILDING = '[Settings] Create Building';
export const UPDATE_BUILDING = '[Settings] Update Building';
export const REMOVE_BUILDING = '[Settings] Remove Building';

export const CREATE_BUILDING_ROOM = '[Settings] Create Building Room';
export const UPDATE_BUILDING_ROOM = '[Settings] Update Building Room';
export const REMOVE_BUILDING_ROOM = '[Settings] Remove Building Room';

export const SET_SELECTED_BRANCH = '[Settings] Set Selected Branch';

export const RESET_SETTINGS = '[Settings] Reset Settings';
export const RESET_ROOMS = '[Settings] Reset Rooms';

export const SUCCESS = '[Settings] Success';
export const ERROR = '[Settings] Error';

export const getIntegrationSettings = createAction(GET_INTEGRATION_SETTINGS);
export const getFeatureSettings = createAction(GET_FEATURE_SETTINGS);
export const getManagersSettings = createAction(GET_MANAGERS_SETTINGS);
export const getBranchesSettings = createAction(GET_BRANCHES_SETTINGS);
export const getAgentsSettings = createAction(GET_AGENTS_SETTINGS, props<{ branchId: string }>());

export const getBuildingManager = createAction(GET_BUILDING_MANAGER);
export const setBuildingManager = createAction(SET_BUILDING_MANAGER, props<{ buildingManagers: object }>());

export const setManagersSettings = createAction(SET_MANAGERS_SETTINGS, props<{ managers: object }>());
export const setBranchesSettings = createAction(SET_BRANCHES_SETTINGS, props<{ branches: object }>());
export const setAgentsSettings = createAction(SET_AGENTS_SETTINGS, props<{ agents: object }>());

export const createBuildingManager = createAction(CREATE_BUILDING_MANAGER, props<{ buildingManager: any }>());
export const updateBuildingManager = createAction(UPDATE_BUILDING_MANAGER, props<{ buildingManager: any }>());
export const removeBuildingManager = createAction(REMOVE_BUILDING_MANAGER, props<{ buildingManager: any }>());

export const createManager = createAction(CREATE_MANAGER, props<{ manager: any }>());
export const updateManager = createAction(UPDATE_MANAGER, props<{ manager: any }>());
export const removeManager = createAction(REMOVE_MANAGER, props<{ manager: any }>());

export const createManagerSuccess = createAction(CREATE_MANAGER_SUCCESS, props<{ response: any }>());
export const updateManagerSuccess = createAction(UPDATE_MANAGER_SUCCESS, props<{ response: any }>());
export const removeManagerSuccess = createAction(REMOVE_MANAGER_SUCCESS, props<{ response: any }>());

export const createAgent = createAction(CREATE_AGENT, props<{ agent: any }>());
export const updateAgent = createAction(UPDATE_AGENT, props<{ agent: any }>());
export const removeAgent = createAction(REMOVE_AGENT, props<{ agent: any }>());

export const createAgentSuccess = createAction(CREATE_AGENT_SUCCESS, props<{ createAgentSuccess: boolean }>());
export const updateAgentSuccess = createAction(UPDATE_AGENT_SUCCESS, props<{ updateAgentSuccess: boolean }>());

export const createBranch = createAction(CREATE_BRANCH, props<{ branch: object }>());
export const updateBranch = createAction(UPDATE_BRANCH, props<{ branch: object }>());
export const removeBranch = createAction(REMOVE_BRANCH, props<{ branch: object }>());

export const createBranchSuccess = createAction(CREATE_BRANCH_SUCCESS, props<{ response: any }>());
export const removeBranchSuccess = createAction(REMOVE_BRANCH_SUCCESS, props<{ response: any }>());
export const updateBranchSuccess = createAction(UPDATE_BRANCH_SUCCESS, props<{ response: any }>());

// IOT
export const getBuildingsSettings = createAction(GET_BUILDINGS_SETTINGS, props<{ branchId: string }>());
export const setBuildingsSettings = createAction(SET_BUILDINGS_SETTINGS, props<{ buildings: object }>());

export const getRoomsSettings = createAction(GET_ROOMS_SETTINGS, props<{ branchId: string; buildingId: string }>());
export const setRoomsSettings = createAction(SET_ROOMS_SETTINGS, props<{ rooms: object }>());

export const createBuilding = createAction(CREATE_BUILDING, props<{ building: any }>());
export const updateBuilding = createAction(UPDATE_BUILDING, props<{ building: any }>());
export const removeBuilding = createAction(REMOVE_BUILDING, props<{ building: any }>());

export const createBuildingRoom = createAction(
  CREATE_BUILDING_ROOM,
  props<{ room: object; branchId: string; buildingId: string }>()
);
export const updateBuildingRoom = createAction(
  UPDATE_BUILDING_ROOM,
  props<{ room: object; branchId: string; buildingId: string }>()
);
export const removeBuildingRoom = createAction(
  REMOVE_BUILDING_ROOM,
  props<{ roomId: string; branchId: string; buildingId: string }>()
);

export const setSelectedBranch = createAction(SET_SELECTED_BRANCH, props<{ selectedBranch: any }>());

export const reset = createAction(RESET_SETTINGS);
export const resetRooms = createAction(RESET_ROOMS);

export const error = createAction(ERROR, props<{ error: string }>());
export const success = createAction(SUCCESS);
