import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { storeLogger } from 'ngrx-store-logger';

import * as fromRouter from '@ngrx/router-store';
import * as StructureReducer from './structure/structure.reducer';
import * as ContentReducer from './content/content.reducer';
import * as AggregationReducer from './aggregation/aggregation.reducer';
import * as AuthReducer from './auth/auth.reducer';
import * as DashboardReducers from './dashboard/dashboard.reducers';
import * as IntegrationReducers from './integration/integration.reducer';
import * as PaymentsReducers from './payments/payments.reducer';
import * as ReportsReducer from './reports/reports.reducer';
import * as IOTReducers from './iot/iot.reducer';
import * as SettingsReducer from './settings/settings.reducer';

import { environment } from 'src/environments/environment';

export interface StoreRootState {
  aggregation: AggregationReducer.State;
  auth: AuthReducer.State;
  router: fromRouter.RouterReducerState<any>;
  structure: StructureReducer.State;
  content: ContentReducer.State;
  dashboard: DashboardReducers.State;
  integration: IntegrationReducers.State;
  payments: PaymentsReducers.State;
  reports: ReportsReducer.State;
  iot: IOTReducers.State;
  settings: SettingsReducer.State;
}

export const reducers: ActionReducerMap<StoreRootState> = {
  aggregation: AggregationReducer.reducer,
  auth: AuthReducer.reducer,
  router: routerReducer,
  structure: StructureReducer.reducer,
  content: ContentReducer.reducer,
  dashboard: DashboardReducers.reducer,
  integration: IntegrationReducers.reducer,
  payments: PaymentsReducers.reducer,
  reports: ReportsReducer.reducer,
  iot: IOTReducers.reducer,
  settings: SettingsReducer.reducer,
};

export function logger(reducer: ActionReducer<StoreRootState>): any {
  return storeLogger()(reducer);
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [] : [];
