import { Injectable } from '@angular/core';
import { timeZone } from 'src/app/core/types/timezone.type';
import * as moment from 'moment-timezone';
import { Default, Format } from '@constants/time.constants';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  public moment$ = moment;
  public userTimeZone: timeZone;

  setLocaleAndTz(timezone: timeZone, language: string) {
    this.userTimeZone = timezone;
    this.moment$.locale(language);
    this.moment$.tz.setDefault(timezone);
  }

  getClientTimezone() {
    return this.moment$.tz.guess() as timeZone;
  }

  getTodayInDefault(date: string) {
    return this.moment$(date).toDate();
  }

  getTimeZone() {
    return this.moment$().toLocaleString();
  }

  getCurrent() {
    return this.moment$().toDate();
  }

  getDate(date: Date) {
    return this.moment$(date).toDate();
  }

  getTooltipDate(date: Date) {
    return this.moment$().format('dddd, MMMM D, h:mm a');
  }

  getDateinTimeZone(date: any, timezone: timeZone, language: string = Default.lang) {
    this.setLocaleAndTz(timezone, language);
    return date ? this.moment$(date) : this.moment$();
  }

  getTimeinDefaultFormat(date: Date) {
    return this.moment$(date).format(Default.format);
  }

  getYesterdayInDefault() {
    return this.moment$().subtract(1, 'd').format(Default.format);
  }

  getTomorrowDate(date: Date) {
    return this.moment$(date).add(1, 'd').format(Default.format);
  }
}
