import { Action, createReducer, on } from '@ngrx/store';
import * as SettingsActions from './settings.actions';
import { updateBranch } from './settings.actions';

interface Loading {
  loading: boolean;
}

interface BranchReducer {
  updateBranch?: Loading;
  createBranch?: Loading;
  removeBranch?: Loading;
}

interface ManagerReducer {
  updateManager?: Loading;
  createManager?: Loading;
  removeManager?: Loading;
}

export interface State {
  structure: object[];
  integration: object[];
  feature: object[];
  managers: object;
  branches: object;
  buildings: object;
  rooms: object;
  manager: ManagerReducer;
  agents: any;
  updateAgentSuccess: boolean;
  createAgentSuccess: boolean;
  branchId: string;
  branch: BranchReducer;
  building: any;
  buildingManagers: any;
  selectedBranch: object[];
}

export const initialState: State = {
  structure: null,
  integration: null,
  feature: null,
  managers: null,
  branches: null,
  buildings: null,
  rooms: null,
  manager: {
    updateManager: {
      loading: false,
    },
    createManager: {
      loading: false,
    },
  },
  agents: null,
  updateAgentSuccess: null,
  createAgentSuccess: null,
  branchId: null,
  branch: {
    updateBranch: {
      loading: false,
    },
    createBranch: {
      loading: false,
    },
  },
  building: null,
  buildingManagers: null,
  selectedBranch: null,
};

const settingsReducer = createReducer(
  initialState,
  on(SettingsActions.getIntegrationSettings, (state) => state),
  on(SettingsActions.getFeatureSettings, (state) => state),
  on(SettingsActions.getManagersSettings, (state) => state),
  on(SettingsActions.getAgentsSettings, (state, { branchId }) => ({
    ...state,
    branchId,
  })),
  on(SettingsActions.getBuildingManager, (state) => state),
  on(SettingsActions.setBuildingManager, (state, { buildingManagers }) => ({
    ...state,
    buildingManagers,
  })),
  on(SettingsActions.setManagersSettings, (state, { managers }) => ({
    ...state,
    managers,
    error: null,
  })),
  on(SettingsActions.getBranchesSettings, (state) => state),
  on(SettingsActions.setBranchesSettings, (state, { branches }) => ({
    ...state,
    branches,
    error: null,
  })),
  on(SettingsActions.setAgentsSettings, (state, { agents }) => ({
    ...state,
    agents,
    error: null,
  })),
  on(SettingsActions.getBuildingsSettings, (state, { branchId }) => ({
    ...state,
    branchId,
  })),
  on(SettingsActions.getRoomsSettings, (state, { buildingId }) => ({
    ...state,
    buildingId,
  })),
  on(SettingsActions.setRoomsSettings, (state, { rooms }) => ({
    ...state,
    rooms,
    error: null,
  })),
  on(SettingsActions.setBuildingsSettings, (state, { buildings }) => ({
    ...state,
    buildings,
    error: null,
  })),
  on(SettingsActions.createBuildingManager, (state, { buildingManager }) => ({
    ...state,
    buildingManager,
  })),
  on(SettingsActions.updateBuildingManager, (state, { buildingManager }) => ({
    ...state,
    buildingManager,
  })),
  on(SettingsActions.removeBuildingManager, (state, { buildingManager }) => ({
    ...state,
    buildingManager,
  })),
  on(SettingsActions.createManager, (state, { manager }) => ({
    ...state,
    manager: {
      createManager: {
        manager,
        loading: true,
      },
    },
  })),
  on(SettingsActions.createManagerSuccess, (state, { response }) => ({
    ...state,
    manager: {
      createManager: {
        ...response.data,
        loading: false,
      },
    },
  })),
  on(SettingsActions.updateManager, (state, { manager }) => ({
    ...state,
    manager: {
      updateManager: {
        manager,
        loading: true,
      },
    },
  })),
  on(SettingsActions.updateManagerSuccess, (state, { response }) => ({
    ...state,
    manager: {
      updateManager: {
        ...response.data,
        loading: false,
      },
    },
  })),
  on(SettingsActions.removeManager, (state, { manager }) => ({
    ...state,
    manager: {
      removeManager: {
        manager,
        loading: true,
      },
    },
  })),
  on(SettingsActions.removeManagerSuccess, (state, { response }) => ({
    ...state,
    manager: {
      removeManager: {
        ...response.data,
        loading: false,
      },
    },
  })),
  on(SettingsActions.createAgent, (state, { agent }) => ({
    ...state,
    agent,
  })),
  on(SettingsActions.updateAgent, (state, { agent }) => ({
    ...state,
    agent,
  })),
  on(SettingsActions.removeAgent, (state, { agent }) => ({
    ...state,
    agent,
  })),
  on(SettingsActions.createAgentSuccess, (state, { createAgentSuccess }) => ({
    ...state,
    createAgentSuccess,
  })),
  on(SettingsActions.updateAgentSuccess, (state, { updateAgentSuccess }) => ({
    ...state,
    updateAgentSuccess,
  })),
  on(SettingsActions.createBranch, (state, { branch }) => ({
    ...state,
    branch: {
      createBranch: {
        branch,
        loading: true,
      },
    },
  })),
  on(SettingsActions.createBranchSuccess, (state, { response }) => ({
    ...state,
    branch: {
      createBranch: {
        ...response.data,
        loading: false,
      },
    },
  })),
  on(SettingsActions.updateBranch, (state, { branch }) => ({
    ...state,
    branch: {
      updateBranch: {
        branch,
        loading: true,
      },
    },
  })),
  on(SettingsActions.removeBranch, (state, { branch }) => ({
    ...state,
    branch: {
      removeBranch: {
        branch,
        loading: true,
      },
    },
  })),
  on(SettingsActions.removeBranchSuccess, (state, { response }) => ({
    ...state,
    branch: {
      removeBranch: {
        ...response.data,
        loading: false,
      },
    },
  })),
  on(SettingsActions.updateBranchSuccess, (state, { response }) => ({
    ...state,
    branch: {
      updateBranch: {
        ...response.data,
        loading: false,
      },
    },
  })),
  on(SettingsActions.createBuilding, (state, { building }) => ({
    ...state,
    building,
  })),
  on(SettingsActions.updateBuilding, (state, { building }) => ({
    ...state,
    building,
  })),
  on(SettingsActions.removeBuilding, (state, { building }) => ({
    ...state,
    building,
  })),
  on(SettingsActions.setSelectedBranch, (state, { selectedBranch }) => ({
    ...state,
    error: null,
    selectedBranch: [selectedBranch],
  })),
  on(SettingsActions.reset, () => ({ ...initialState })),
  on(SettingsActions.resetRooms, (state) => ({
    ...state,
    rooms: [],
  })),
  on(SettingsActions.error, (state) => state)
);

export function reducer(state: State | undefined, action: Action) {
  return settingsReducer(state, action);
}
