import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';

@Injectable({
  providedIn: 'root',
})
export class IOTService {
  private iotFunctions = this.cloudFunction.iot();

  constructor(private cloudFunction: CloudFunctionsService) {}

  async get(payload) {
    return this.iotFunctions.getIOTHistoricalQuery(payload);
  }

  getIOTHistoricalQuery(data) {
    return from(this.get(data) as Promise<any>);
  }
}
