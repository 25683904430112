import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';

import { REGION } from 'src/app/core/types/region.type';
import { INTEGRATION } from 'src/app/core/constants/integration.constants';
import { from } from 'rxjs';

export interface CloudFunctionsCallableAPI {
  [key: string]: firebase.functions.HttpsCallable;
}

@Injectable({
  providedIn: 'root',
})
export class CloudFunctionsService {
  region: REGION = 'asia-northeast1';
  regionUW3: REGION = 'europe-west3';
  functions = firebase.app().functions(this.region);
  funcUW3 = firebase.app().functions(this.regionUW3);

  manager(): CloudFunctionsCallableAPI {
    return {
      createManager: this.functions.httpsCallable('merchant-manager-create'),
      updateManager: this.functions.httpsCallable('merchant-manager-update'),
      deleteManager: this.functions.httpsCallable('merchant-manager-delete'),
    };
  }

  merchant(): CloudFunctionsCallableAPI {
    return {
      createmerchantOnboarding: this.functions.httpsCallable('merchant-onboarding-create'),
    };
  }

  agent(): CloudFunctionsCallableAPI {
    return {
      createAgent: this.functions.httpsCallable('merchantCreateAgent'),
      updateAgentStatus: this.functions.httpsCallable('updateAgentStatusOnCall'),
    };
  }

  branch(): CloudFunctionsCallableAPI {
    return {
      createBranch: this.functions.httpsCallable('merchant-branch-create'),
      deleteBranch: this.functions.httpsCallable('merchant-branch-delete'),
      updateBranch: this.functions.httpsCallable('merchant-branch-update'),
    };
  }

  iot(): CloudFunctionsCallableAPI {
    return {
      getIOTHistoricalQuery: this.funcUW3.httpsCallable('iotHistoricalQuery'),
    };
  }

  get(delivery: string): CloudFunctionsCallableAPI {
    const METHODS = INTEGRATION.DELIVERY[delivery];
    return {
      getQuote: this.functions.httpsCallable(METHODS.GET_QUOTE),
      createOrder: this.functions.httpsCallable(METHODS.CREATE_ORDER),
      getOrder: this.functions.httpsCallable(METHODS.GET_ORDER),
      cancelOrder: this.functions.httpsCallable(METHODS.CANCEL_ORDER),
      getCourier: this.functions.httpsCallable(METHODS.GET_COURIER),
      getLocation: this.functions.httpsCallable(METHODS.GET_LOCATION),
    };
  }

  order(): CloudFunctionsCallableAPI {
    return {
      updateStatus: this.functions.httpsCallable(INTEGRATION.ORDER.UPDATE),
    };
  }

  delivery(): CloudFunctionsCallableAPI {
    return {
      create: this.functions.httpsCallable(INTEGRATION.DELIVERY.CREATE),
    };
  }

  paymongo(): CloudFunctionsCallableAPI {
    return {
      createPaymentMethod: this.functions.httpsCallable(INTEGRATION.PAYMONGO.CREATE_PAYMENT_METHOD),
    };
  }

  payment(): CloudFunctionsCallableAPI {
    return {
      create: this.functions.httpsCallable(INTEGRATION.PAYMENT.CREATE),
      update: this.functions.httpsCallable(INTEGRATION.PAYMENT.UPDATE),
    };
  }

  location(): CloudFunctionsCallableAPI {
    return {
      update: this.functions.httpsCallable(INTEGRATION.LOCATION.UPDATE),
    };
  }
}
