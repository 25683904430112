import { Platform } from './../../models/data/platform.model';
import { createAction, props } from '@ngrx/store';
import { OrderDetails, RealTimeOrder } from 'src/app/models/data/order.model';
import { DeliveryDetails } from 'src/app/models/integration/delivery.model';
import { DeliveryPlatformParam } from '@models/state/integration/actions.props';

export const CANCEL_DELIVERY_ORDER = '[Integration] Cancel Delivery Order';
export const REASSIGN_DELIVERY_ORDER = '[Integration] Reassign Delivery Order';
export const CANCEL_DELIVERY_ORDER_SUCCESS = '[Integration] Cancel Delivery Order Success';
export const CANCEL_DELIVERY_ORDER_ERROR = '[Integration] Cancel Delivery Order Error';
export const REMOVE_DELIVERY_ORDER = '[Integration] Remove Delivery Order';
export const GET_DELIVERY_PRICING = '[Integration] Get Delivery Pricing';
export const SET_DELIVERY_PRICING = '[Integration] Set Delivery Pricing';
export const GET_DELIVERY_COURIER = '[Integration] Get Delivery Courier';
export const SET_DELIVERY_COURIER = '[Integration] Set Delivery Courier';
export const GET_DELIVERY_LOCATION = '[Integration] Get Delivery Location';
export const GET_ORDER_DETAILS = '[Integration] Get Order Details';
export const SET_ORDER_DETAILS = '[Integration] Set Order Details';
export const INITIALIZE_DELIVERY_PRICING = '[Integration] Initialize Delivery Pricing';

export const SET_SELECTED_DELIVERY_PLATFORM = '[Integration] Set Selected Delivery Platform';

export const ASSIGN_DELIVERY_PLATFORM = '[Integration] Assign Delivery Platform';

export const ASSIGN_WHITELABEL_DELIVERY_PLATFORM_IN_OPERATION =
  '[Integration] Assign White Label Delivery Platform In Operation';

export const AUTO_ASSIGN_WHITELABEL_DELIVERY_PLATFORM_IN_DELIVERY =
  '[Integration] Auto Assign White Label Delivery Platform';

export const ASSIGN_WHITELABEL_DELIVERY_PLATFORM_IN_DELIVERY = '[Integration] Assign To White Label Delivery Platform';

export const MULTI_DROPOFF_ASSIGN_WHITELABEL_DELIVERY_PLATFORM_IN_DELIVERY =
  '[Integration] Multi Drop Off Assign To White Label Delivery Platform';

export const GET_WHITELABEL_AGENTS = '[Integration] Get White Label Agents';
export const SET_WHITELABEL_AGENTS = '[Integration] Set White Label Agents';

export const SET_DELIVERY_DETAILS = '[Integration] Set Delivery Detais';
export const SET_WHITELABEL_DELIVERY_DETAILS = '[Integration] Set White Label Delivery Detais';
export const SET_WHITELABEL_AUTO_ALLOCATION_DELIVERY_DETAILS = '[Integration] Set Auto Allocation Delivery Detais';
export const SET_DELIVERY_DETAILS_SUCCESS = '[Integration] Set Delivery Detais Success';
export const UPDATE_ORDER_DETAILS = '[Integration] Update Order Detais';
export const UPDATE_ORDER_DETAILS_SUCCESS = '[Integration] Update Order Detais Success';

export const RESET_INTEGRATION = '[Integration] Reset Integration';

export const SUCCESS = '[Integration] Success';
export const ERROR = '[Integration] Error';

export const getDeliveryPricing = createAction(
  GET_DELIVERY_PRICING,
  props<{ orderDetails: OrderDetails; merchantOwnerUid: string }>()
);
export const setDeliveryPricing = createAction(SET_DELIVERY_PRICING, props<{ pricing: any }>());
export const initializeDeliveryPricing = createAction(INITIALIZE_DELIVERY_PRICING);

// thirdy party delivery
export const setSelectedDeliveryPlatform = createAction(
  SET_SELECTED_DELIVERY_PLATFORM,
  props<{ platform: Platform }>()
);
export const cancelDeliveryOrder = createAction(
  CANCEL_DELIVERY_ORDER,
  props<{ platform: string; branchId: string; merchantId: string; orderDetails: RealTimeOrder }>()
);
export const reassignDeliveryOrder = createAction(
  REASSIGN_DELIVERY_ORDER,
  props<{
    platform: string;
    newPlatform: Platform;
    branchId: string;
    merchantId: string;
    orderId: string;
    orderDetails: RealTimeOrder;
  }>()
);
export const cancelDeliveryOrderSuccess = createAction(CANCEL_DELIVERY_ORDER_SUCCESS, props<{ result }>());
export const cancelDeliveryOrderError = createAction(CANCEL_DELIVERY_ORDER_ERROR, props<{ error: Error }>());

export const assignDeliveryPlatform = createAction(
  ASSIGN_DELIVERY_PLATFORM,
  props<{ deliveryPlatformParam: DeliveryPlatformParam }>()
);

export const getDeliveryCourier = createAction(
  GET_DELIVERY_COURIER,
  props<{ platform: string; branchId: string; merchantId: string; orderDetails: RealTimeOrder }>()
);

export const getDeliveryLocation = createAction(
  GET_DELIVERY_LOCATION,
  props<{ platform: string; branchId: string; merchantId: string; orderDetails: RealTimeOrder }>()
);

export const getOrderDetails = createAction(
  GET_ORDER_DETAILS,
  props<{ platform: string; branchId: string; merchantId: string; orderDetails: RealTimeOrder }>()
);
// White Label Delivery
export const assignWhiteLabelDeliveryPlatformInOperation = createAction(
  ASSIGN_WHITELABEL_DELIVERY_PLATFORM_IN_OPERATION,
  props<{ agentId: string; merchantOwnerUid: string }>()
);
export const assignWhiteLabelDeliveryPlatformInDelivery = createAction(
  ASSIGN_WHITELABEL_DELIVERY_PLATFORM_IN_DELIVERY,
  props<{ orders: any; agentId: string; merchantOwnerUid: string }>()
);
export const multiDropOffAssignWhiteLabelDeliveryPlatformInDelivery = createAction(
  MULTI_DROPOFF_ASSIGN_WHITELABEL_DELIVERY_PLATFORM_IN_DELIVERY,
  props<{ agentId: string; merchantOwnerUid: string }>()
);
export const autoAssignWhiteLabelDeliveryPlatformInDelivery = createAction(
  AUTO_ASSIGN_WHITELABEL_DELIVERY_PLATFORM_IN_DELIVERY,
  props<{ agentId: string; merchantOwnerUid: string }>()
);
export const getWhiteLabelAgents = createAction(GET_WHITELABEL_AGENTS, props<{ branchId: string }>());
export const setWhiteLabelAgents = createAction(SET_WHITELABEL_AGENTS, props<{ agents: any }>());

export const setDeliveryCourier = createAction(SET_DELIVERY_COURIER, props<{ courier: any }>());
export const removeDeliveryOrder = createAction(REMOVE_DELIVERY_ORDER, props<{ orderId: string }>());
export const setDeliveryDetails = createAction(
  SET_DELIVERY_DETAILS,
  props<{ deliveryOrder: DeliveryDetails; orderId: string; branchId: string; merchantOwnerUid: string }>()
);
export const setWhiteLabelDeliveryDetails = createAction(SET_WHITELABEL_DELIVERY_DETAILS, props<{ message: any }>());
export const setWhiteLabelAutoAllocationDeliveryDetails = createAction(
  SET_WHITELABEL_AUTO_ALLOCATION_DELIVERY_DETAILS,
  props<{ message: any }>()
);
export const setDeliveryDetailsSuccess = createAction(SET_DELIVERY_DETAILS_SUCCESS);
export const updateOrderDetails = createAction(
  UPDATE_ORDER_DETAILS,
  props<{ branchId: string; orderDetails: OrderDetails; deliveryOrder: DeliveryDetails }>()
);
export const updateOrderDetailsSuccess = createAction(UPDATE_ORDER_DETAILS_SUCCESS);

export const reset = createAction(RESET_INTEGRATION);

export const success = createAction(SUCCESS, props<{ response: any }>());
export const error = createAction(ERROR, props<{ error: Error }>());
