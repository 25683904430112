import { Action, createReducer, on } from '@ngrx/store';
import { PrioritiesByDates, RealTimePriorities } from 'src/app/models/data/priority.model';
import * as IOTActions from './iot.actions';
import * as Time from 'src/app/core/utils/time';

export interface State {
  devices: object;
  latestScore: object;
  dailySensor: object;
  historicalData: object;

  // Filter
  selectedPriorityFilter: string; // priority
  selectedDateFilter: string; // YYYY/M/D

  // SelectedBranch
  selectedBranch: object[];
  selectedBuilding: object[];
  selectedRoom: object;
  prioritiesByDates: PrioritiesByDates;
}

export const initialState: State = {
  devices: null,
  latestScore: null,
  dailySensor: null,
  historicalData: null,
  selectedPriorityFilter: '',
  selectedDateFilter: Time.moment$().format(Time.defaultFormat),
  selectedBranch: [],
  selectedBuilding: [],
  selectedRoom: {},
  prioritiesByDates: {
    loading: true,
    priorities: {},
  },
};

const iotReducer = createReducer(
  initialState,

  on(IOTActions.getIOTDevices, (state) => state),
  on(IOTActions.setIOTDevices, (state, devices) => ({
    ...state,
    devices: {
      ...devices.devices,
    },
    error: null,
  })),
  on(IOTActions.getLatestScore, (state) => state),
  on(IOTActions.setLatestScore, (state, latest) => ({
    ...state,
    latestScore: {
      ...latest.score,
    },
    error: null,
  })),
  on(IOTActions.getDailySensorData, (state) => state),
  on(IOTActions.setDailySensorData, (state, month) => ({
    ...state,
    dailySensor: {
      ...month.daily,
    },
    error: null,
  })),
  on(IOTActions.getIOTHistoricalData, (state) => state),
  on(IOTActions.setIOTHistoricalData, (state, historicalData) => ({
    ...state,
    historicalData: {
      ...historicalData.historicalData,
    },
    error: null,
  })),

  // Selected Dropdowns
  on(IOTActions.setSelectedBranch, (state, selectedBranch) => ({
    ...state,
    selectedBranch: [selectedBranch.branch],
    error: null,
  })),

  on(IOTActions.setSelectedBuilding, (state, selectedBuilding) => ({
    ...state,
    selectedBuilding: [selectedBuilding.building],
    error: null,
  })),

  on(IOTActions.setSelectedRoom, (state, selectedRoom) => ({
    ...state,
    selectedRoom: selectedRoom.room,
    error: null,
  })),

  // Reset
  on(IOTActions.reset, () => ({ ...initialState })),
  on(IOTActions.error, (state) => state)
);

export function reducer(state: State | undefined, action: Action) {
  return iotReducer(state, action);
}
