import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';

@Injectable({
  providedIn: 'root',
})
export class ManagerService {
  private managerFunctions = this.cloudFunction.manager();

  constructor(private cloudFunction: CloudFunctionsService) {}

  async create(manager) {
    return this.managerFunctions.createManager(manager);
  }

  async update(manager) {
    return this.managerFunctions.updateManager(manager);
  }

  async delete(manager) {
    return this.managerFunctions.deleteManager(manager);
  }

  createManager(manager) {
    const response = from(this.create(manager) as Promise<any>);
    return response;
  }

  updateManager(manager) {
    const response = from(this.update(manager) as Promise<any>);
    return response;
  }

  deleteManager(manager) {
    const response = from(this.delete(manager) as Promise<any>);
    return response;
  }
}
