import { map, catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { AuthService } from 'src/app/services/firebase/auth.service';
import { PageStructure } from 'src/app/models/structure/page.structure.model';
import { User } from '../auth/auth.model';

import * as StructureActions from './structure.actions';
import { Observable, of } from 'rxjs';

@Injectable()
export class StructureEffects {
  // setPageStructure$ = createEffect(() =>
  //     this.actions$.pipe(
  //     ofType(StructureActions.SET_PAGE_STRUCTURE),
  //     mergeMap(({structure}) => this.authService.userDoc$.pipe(
  //         map((user: User) => {
  //             const newStructure: PageStructure = structure;
  //             if (user) {
  //                 return structureActions.setPageStructureAuthenticated({ structure });
  //             } else {
  //                 return structureActions.setPageStructureUnauthenticated({ structure: { auth: newStructure.auth }});
  //             }
  //         }),
  //         catchError(error => of(structureActions.error({ error })))
  //     ))
  // ));

  constructor(private actions$: Actions, private authService: AuthService) {}
}
