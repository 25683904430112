import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as Time from 'src/app/core/utils/time';
import { Field, FieldName } from '@constants/types.constants';
import { TranslateService } from '@ngx-translate/core';
import { FIELD_TYPE } from '@constants/global.constants';

@Component({
  selector: 'app-details-field',
  templateUrl: './details-field.component.html',
  styleUrls: ['./details-field.component.scss'],
})
export class DetailsFieldComponent {
  @Input() item: any;
  @Input() fieldType: Field;
  @Input() fieldName: FieldName;

  @Output() outputPaymentLink = new EventEmitter<string>();

  public moment = Time.moment$;
  public date = FIELD_TYPE.date;
  public string = FIELD_TYPE.string;

  constructor(private translate: TranslateService) {}

  getTranslation() {
    let fieldNameTranslation: string;
    this.translate
      .get(`DELIVERY.DETAILS.${this.fieldName}`)
      .subscribe((result: string) => {
        fieldNameTranslation = result;
      })
      .unsubscribe();
    return fieldNameTranslation;
  }
}
