import { createAction, props } from '@ngrx/store';
import { OrderDetails, RealTimeOrder } from '@models/data/order.model';
import { Activity } from '@models/v2/activity.model';
import { Agent, RTDBAgent } from '@models/v2/agent.model';
import { Branch } from '@models/data/branch.model';
import { Location } from '@models/v2/location.model';
import { Task } from '@models/v2/task.model';
import { Merchant } from '@models/v2/merchant.model';
import { RealTimeDelivery } from '@models/v2/delivery.model';

// AGENTS

export const GET_REALTIME_AGENTS = '[Dashboard - Delivery] Get Real Time Agents';
export const SET_REALTIME_AGENTS = '[Dashboard - Delivery] Set Real Time Agents';

export const SET_SELECTED_BRANCH = '[Dashboard - Delivery] Set Selected Branch';
export const SET_SELECTED_SORTING = '[Dashboard - Delivery] Set Selected Sorting';

export const GET_SELECTED_BRANCH_LOCATION = '[Dashboard - Delivery] Get Selected Branch Location';
export const SET_SELECTED_BRANCH_LOCATION = '[Dashboard - Delivery] Set Selected Branch Location';

export const SET_SELECTED_DATE_FITLER = '[Dashboard - Delivery] Set Selected Date Filter';
export const SET_SELECTED_NEXT_DATE_FITLER = '[Dashboard - Delivery] Set Selected Next Date Filter';

export const RESET_DELIVERY = '[Content] Reset Delivery';

// ORDERS

export const GET_MONTHLY_ORDERS_AGGREGATION = '[ Dashboard - Delivery ] Get Monthly Orders Aggregation';
export const SET_MONTHLY_ORDERS_AGGREGATION = '[ Dashboard - Delivery ] Set Monthly Orders Aggregation';

export const GET_MONTHLY_ORDERS = '[ Dashboard - Delivery ] Get Monthly Orders';
export const SET_MONTHLY_ORDERS = '[ Dashboard - Delivery ] Set Monthly Orders';

export const GET_ADVANCE_ORDERS = '[Dashboard - Delivery] Get Advance Orders';
export const SET_ADVANCE_ORDERS = '[Dashboard - Delivery] Set Advance Orders';
export const SET_SELECTED_ADVANCE_ORDERS = '[Dashboard - Delivery] Set Selected Advance Orders';
export const SET_SELECTED_ADVANCE_ORDERS_DISPATCH_TIME =
  '[Dashboard - Delivery] Set Selected Advance Orders Dispatch Time';

export const UPDATE_ORDER_STATUS = '[Dashboard - Delivery] Update Order Status';
export const UPDATE_ORDER_STATUS_SUCCESS = '[Dashboard - Delivery] Update Order Status Success';
export const UPDATE_ORDER_STATUS_ERROR = '[Dashboard - Delivery] Update Order Status Error';

export const INSERT_ORDER_TO_QUEUE = '[Dashboard - Delivery] Insert Order To Queue';
export const SHIFT_ORDER_TO_QUEUE = '[Dashboard - Delivery] Shift Order To Queue';
export const ASSIGN_QUEUED_ORDER_TO_AGENT = '[Dashboard - Delivery] Assign Queued Order To Agent';

export const GET_REALTIME_DELIVERIES = '[Dashboard - Delivery] Get Real Time Deliveries';
export const SET_REALTIME_DELIVERIES = '[Dashboard - Delivery] Set Real Time Deliveries';

export const GET_REALTIME_ORDERS = '[Dashboard - Delivery] Get Real Time Orders';
export const GET_NEXT_DAY_REALTIME_ORDERS = '[Dashboard - Delivery] Get Next Day Real Time Orders';
export const SET_REALTIME_ORDERS = '[Dashboard - Delivery] Set Real Time Orders';
export const SET_NEXT_DAY_REALTIME_ORDERS = '[Dashboard - Delivery] Set Next Day Real Time Orders';
export const INITIALIZE_REALTIME_ORDERS = '[Dashboard - Delivery] Initialize Real Time Orders';

export const GET_ORDER_DETAILS = '[Dashboard - Delivery] Get Order Details';
export const SET_ORDER_DETAILS = '[Dashboard - Delivery] Set Order Details';

export const GET_AGENT_DETAILS = '[Dashboard - Delivery] Get Agent Details';
export const SET_AGENT_DETAILS = '[Dashboard - Delivery] Set Agent Details';

export const SUCCESS = '[Dashboard - Delivery] Success';
export const ERROR = '[Dashboard - Delivery] Error';

export const SET_SELECTED_REAL_TIME_ORDER = '[Dashboard - Delivery] Set Selected Real Time Order';
export const SET_SELECTED_ORDER_DETAILS = '[Dashboard - Delivery] Set Selected Order Details';
export const INITIALIZE_SELECTED_ORDER_DETAILS = '[Dashboard - Delivery] Initialize Selected Order Details';

export const SET_SELECTED_REAL_TIME_AGENT = '[Dashboard - Delivery] Set Selected Real Time Agent';
export const SET_SELECTED_AGENT_DETAILS = '[Dashboard - Delivery] Set Selected Agent Details';
export const INITIALIZE_SELECTED_AGENT_DETAILS = '[Dashboard - Delivery] Initialize Selected Agent Details';

export const UPDATE_AGENT_STATUS = '[ Dashboard - Delivery ] Update Agent Status';
export const UPDATE_AGENT_STATUS_SUCCESS = '[ Dashboard - Delivery ] Update Agent Status Success';
export const UPDATE_AGENT_STATUS_ERROR = '[ Dashboard - Delivery ] Update Agent Status Error';

// Get order activities
export const GET_ORDER_ACTIVITIES = '[Dashboard - Delivery] Get Order Activities';
export const SET_SELECTED_ORDER_ACTIVITIES = '[Dashboard - Delivery] Set Selected Order Activities';

// Get agent activities
export const GET_AGENT_ACTIVITIES = '[Dashboard - Delivery] Get Agent Activities';
export const SET_SELECTED_AGENT_ACTIVITIES = '[Dashboard - Delivery] Set Selected Agent Activities';

// Pin Location
export const UPDATE_DELIVERY_ADDRESS = '[Dashboard - Delivery] Update Delivery Address';
export const UPDATE_DELIVERY_ADDRESS_SUCCESS = '[ Dashboard - Delivery ] Update Delivery Address Success';
export const UPDATE_DELIVERY_ADDRESS_ERROR = '[ Dashboard - Delivery ] Update Delivery Address Error';
export const SET_ORDER_PIN_LOCATION = '[Dashboard - Delivery] Set Order Pin Location';

// Order activities
export const setSelectedBranch = createAction(SET_SELECTED_BRANCH, props<{ selectedBranch: any }>());
export const setSelectedSorting = createAction(SET_SELECTED_SORTING, props<{ selectedSorting: any }>());
export const setSelectedDateFilter = createAction(SET_SELECTED_DATE_FITLER, props<{ selectedDateFilter: string }>());
export const setSelectedNextDateFilter = createAction(
  SET_SELECTED_NEXT_DATE_FITLER,
  props<{ selectedNextDateFilter: string }>()
);

// Agent activities
export const getRealTimeAgents = createAction(GET_REALTIME_AGENTS, props<{ selectedBranch: string }>());
export const setRealTimeAgents = createAction(SET_REALTIME_AGENTS, props<{ agents: any }>());

export const getSelectedBranchLocation = createAction(
  GET_SELECTED_BRANCH_LOCATION,
  props<{ selectedBranch: string }>()
);
export const setSelectedBranchLocation = createAction(SET_SELECTED_BRANCH_LOCATION, props<{ location: Location }>());

export const reset = createAction(RESET_DELIVERY);

export const error = createAction(ERROR, props<{ error: Error }>());
export const success = createAction(SUCCESS);

// ORDER
export const getAdvanceOrders = createAction(GET_ADVANCE_ORDERS, props<{ selectedBranch: string }>());
export const setAdvanceOrders = createAction(SET_ADVANCE_ORDERS, props<{ orders: any }>());
export const setSelectedAdvanceOrders = createAction(SET_SELECTED_ADVANCE_ORDERS, props<{ advanceOrder: any }>());
export const setAdvanceOrderDispatchTime = createAction(
  SET_SELECTED_ADVANCE_ORDERS_DISPATCH_TIME,
  props<{ advanceOrder: any; dispatchTime: any }>()
);

export const insertOrderToQueue = createAction(
  INSERT_ORDER_TO_QUEUE,
  props<{ selectedRealTimeOrder?: RealTimeOrder }>()
);
export const shiftOrderToQueue = createAction(SHIFT_ORDER_TO_QUEUE, props<{ orders: RealTimeOrder[] }>());
export const assignQueuedOrder = createAction(
  ASSIGN_QUEUED_ORDER_TO_AGENT,
  props<{ assignQueuedOrder: RealTimeOrder }>()
);

export const getOrderDetails = createAction(GET_ORDER_DETAILS, props<{ id: string; branchId: string }>());
export const setOrderDetails = createAction(SET_ORDER_DETAILS, props<{ orderDetails: OrderDetails }>());

export const getRealTimeDeliveries = createAction(
  GET_REALTIME_DELIVERIES,
  props<{ selectedBranch: string; dateFilter: string }>()
);

export const setRealTimeDeliveries = createAction(SET_REALTIME_DELIVERIES, props<{ deliveries: RealTimeDelivery[] }>());

export const getRealTimeOrders = createAction(
  GET_REALTIME_ORDERS,
  props<{ selectedBranch: string; dateFilter: string }>()
);
export const getNextDayRealTimeOrders = createAction(
  GET_NEXT_DAY_REALTIME_ORDERS,
  props<{ selectedBranch: string; dateFilter: string }>()
);
export const setRealTimeOrders = createAction(SET_REALTIME_ORDERS, props<{ orders: RealTimeOrder[] }>());
export const setNextDayRealTimeOrders = createAction(
  SET_NEXT_DAY_REALTIME_ORDERS,
  props<{ orders: RealTimeOrder[] }>()
);

export const getMonthlyRealTimeOrders = createAction(
  GET_MONTHLY_ORDERS,
  props<{ selectedBranch: string; dateFilter: string }>()
);
export const setMonthlyRealTimeOrders = createAction(SET_MONTHLY_ORDERS, props<{ orders: RealTimeOrder[] }>());

export const initializeRealTimeOrders = createAction(INITIALIZE_REALTIME_ORDERS);

export const setSelectedRealTimeOrder = createAction(
  SET_SELECTED_REAL_TIME_ORDER,
  props<{ selectedRealTimeOrder?: RealTimeOrder }>()
);

export const setSelectedOrderDetails = createAction(
  SET_SELECTED_ORDER_DETAILS,
  props<{ selectedOrderDetails: OrderDetails }>()
);

// ORDER AGGREGATION

export const getMonthlyOrdersAggregation = createAction(
  GET_MONTHLY_ORDERS_AGGREGATION,
  props<{ selectedBranch: string; dateFilter: any }>()
);
export const setMonthlyOrdersAggregation = createAction(SET_MONTHLY_ORDERS_AGGREGATION, props<{ aggregation: any }>());

export const getAgentDetails = createAction(GET_AGENT_DETAILS, props<{ id: string }>());
export const setAgentDetails = createAction(SET_AGENT_DETAILS, props<{ agentDetails: Agent }>());

export const setSelectedRealTimeAgent = createAction(
  SET_SELECTED_REAL_TIME_AGENT,
  props<{ selectedRealTimeAgent?: RTDBAgent }>()
);

export const setSelectedAgentDetails = createAction(
  SET_SELECTED_AGENT_DETAILS,
  props<{ selectedAgentDetails: Agent }>()
);

// Get order activities
export const getOrderActivities = createAction(GET_ORDER_ACTIVITIES, props<{ id: string }>());
export const setSelectedOrderActivities = createAction(
  SET_SELECTED_ORDER_ACTIVITIES,
  props<{ activities: Activity[] }>()
);

// Get agent activities
export const getAgentActivities = createAction(GET_AGENT_ACTIVITIES, props<{ id: string }>());
export const setSelectedAgentActivities = createAction(
  SET_SELECTED_AGENT_ACTIVITIES,
  props<{ activities: Activity[] }>()
);

export const initializeSelectedOrderDetails = createAction(INITIALIZE_SELECTED_ORDER_DETAILS);
export const initializeSelectedAgentDetails = createAction(INITIALIZE_SELECTED_AGENT_DETAILS);

export const updateOrderStatus = createAction(
  UPDATE_ORDER_STATUS,
  props<{ branchId: string; merchantId: string; orderDetails: RealTimeOrder; orderStatus: number }>()
);
export const updateOrderStatusSuccess = createAction(UPDATE_ORDER_STATUS_SUCCESS, props<{ result }>());
export const updateOrderStatusError = createAction(UPDATE_ORDER_STATUS_ERROR, props<{ error: Error }>());

export const updateAgentStatus = createAction(
  UPDATE_AGENT_STATUS,
  props<{ branchId: string; merchantId: string; agentId: string; status: number }>()
);
export const updateAgentStatusSuccess = createAction(UPDATE_AGENT_STATUS_SUCCESS, props<{ result }>());
export const updateAgentStatusError = createAction(UPDATE_AGENT_STATUS_ERROR, props<{ error: Error }>());

// Pin Location
export const updateDeliveryAddress = createAction(
  UPDATE_DELIVERY_ADDRESS,
  props<{ orderDetails: OrderDetails; selectedPlace: Location; manager: Merchant }>()
);

export const updateDeliveryAddressSuccess = createAction(UPDATE_DELIVERY_ADDRESS_SUCCESS, props<{ result }>());
export const updateDeliveryAddressError = createAction(UPDATE_DELIVERY_ADDRESS_ERROR, props<{ error: Error }>());

export const setOrderPinLocation = createAction(
  SET_ORDER_PIN_LOCATION,
  props<{ order: OrderDetails; lat: string; lng: string }>()
);
