import { map, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

// services
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ReportsService } from '../../services/reports/reports.service';

// state
import * as ReportsActions from './reports.actions';

@Injectable()
export class ReportsEffects {
  constructor(private actions$: Actions, private authService: AuthService, private reportsService: ReportsService) {}

  getDailyOrdersReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.GET_DAILY_ORDERS_REPORTS),
        mergeMap(({ branch, year, month, node }) =>
          this.reportsService.getDailyReports(branch, year, month, node).pipe(
            map((daily: any) => {
              return ReportsActions.setDailyOrdersReports({ daily });
            }),
            catchError((err) => of(ReportsActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getMonthlyOrdersReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.GET_MONTHLY_ORDERS_REPORTS),
        mergeMap(({ branch, year, node }) =>
          this.reportsService.getMonthlyReports(branch, year, node).pipe(
            map((monthly: any) => {
              return ReportsActions.setMonthlyOrdersReports({ monthly });
            }),
            catchError((err) => of(ReportsActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getWeeklyOrdersReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.GET_WEEKLY_ORDERS_REPORTS),
        mergeMap(({ branch, year, node }) =>
          this.reportsService.getWeeklyReports(branch, year, node).pipe(
            map((monthly: any) => {
              return ReportsActions.setWeeklyOrdersReports({ weekly: monthly });
            }),
            catchError((err) => of(ReportsActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getDailyDeliveriesReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.GET_DAILY_DELIVERIES_REPORTS),
        mergeMap(({ branch, year, month, node }) =>
          this.reportsService.getDailyReports(branch, year, month, node).pipe(
            map((daily: any) => {
              return ReportsActions.setDailyDeliveriesReports({ daily });
            }),
            catchError((err) => of(ReportsActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getMonthlyDeliveriesReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.GET_MONTHLY_DELIVERIES_REPORTS),
        mergeMap(({ branch, year, node }) =>
          this.reportsService.getMonthlyReports(branch, year, node).pipe(
            map((monthly: any) => {
              return ReportsActions.setMonthlyDeliveriesReports({ monthly });
            }),
            catchError((err) => of(ReportsActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getWeeklyDeliveriesReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.GET_WEEKLY_DELIVERIES_REPORTS),
        mergeMap(({ branch, year, node }) =>
          this.reportsService.getWeeklyReports(branch, year, node).pipe(
            map((monthly: any) => {
              return ReportsActions.setWeeklyDeliveriesReports({ weekly: monthly });
            }),
            catchError((err) => of(ReportsActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getDailyPaymentsReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.GET_DAILY_PAYMENTS_REPORTS),
        mergeMap(({ branch, year, month, node }) =>
          this.reportsService.getDailyReports(branch, year, month, node).pipe(
            map((daily: any) => {
              return ReportsActions.setDailyPaymentsReports({ daily });
            }),
            catchError((err) => of(ReportsActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getMonthlyPaymentsReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.GET_MONTHLY_PAYMENTS_REPORTS),
        mergeMap(({ branch, year, node }) =>
          this.reportsService.getMonthlyReports(branch, year, node).pipe(
            map((monthly: any) => {
              return ReportsActions.setMonthlyPaymentsReports({ monthly });
            }),
            catchError((err) => of(ReportsActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getWeeklyPaymentsReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.GET_WEEKLY_PAYMENTS_REPORTS),
        mergeMap(({ branch, year, node }) =>
          this.reportsService.getWeeklyReports(branch, year, node).pipe(
            map((weekly: any) => {
              return ReportsActions.setWeeklyPaymentsReports({ weekly });
            }),
            catchError((err) => of(ReportsActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getDailySalesReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.GET_DAILY_SALES_REPORTS),
        mergeMap(({ branch, year, month, node }) =>
          this.reportsService.getDailyReports(branch, year, month, node).pipe(
            map((daily: any) => {
              return ReportsActions.setDailySalesReports({ daily });
            }),
            catchError((err) => of(ReportsActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getMonthlySalesReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.GET_MONTHLY_SALES_REPORTS),
        mergeMap(({ branch, year, node }) =>
          this.reportsService.getMonthlyReports(branch, year, node).pipe(
            map((monthly: any) => {
              return ReportsActions.setMonthlySalesReports({ monthly });
            }),
            catchError((err) => of(ReportsActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getWeeklySalesReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.GET_WEEKLY_SALES_REPORTS),
        mergeMap(({ branch, year, node }) =>
          this.reportsService.getWeeklyReports(branch, year, node).pipe(
            map((monthly: any) => {
              return ReportsActions.setWeeklySalesReports({ weekly: monthly });
            }),
            catchError((err) => of(ReportsActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );
}
