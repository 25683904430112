import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';

@Injectable({
  providedIn: 'root',
})
export class BranchService {
  private branchFunctions = this.cloudFunction.branch();

  constructor(private cloudFunction: CloudFunctionsService) {}

  async create(branch) {
    return await this.branchFunctions.createBranch(branch);
  }

  async delete(branch) {
    return await this.branchFunctions.deleteBranch(branch);
  }

  async update(branch) {
    return await this.branchFunctions.updateBranch(branch);
  }

  createBranch(branch) {
    const response = from(this.create(branch) as Promise<any>);
    return response;
  }

  deleteBranch(branch) {
    const response = from(this.delete(branch) as Promise<any>);
    return response;
  }

  updateBranch(branch) {
    const response = from(this.update(branch) as Promise<any>);
    return response;
  }
}
