import { Injectable } from '@angular/core';
import { analytics } from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor() {
    analytics().setAnalyticsCollectionEnabled(true);
  }

  setUserId(userId) {
    analytics().setUserId(userId);
  }

  setUserProperties(userProps) {
    analytics().setUserProperties(userProps);
  }

  logEvent(eventName, eventParams) {
    analytics().logEvent(eventName, eventParams);
  }

  setCurrentScreen(screenName) {
    analytics().setCurrentScreen(screenName);
  }
}
