import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { RTDB_CONSTANTS } from 'src/app/core/constants/rtdb.constants';
import { RealTimeOrder, OrderDetails } from 'src/app/models/data/order.model';

import { RTDBService } from 'src/app/services/firebase/rtdb.service';
import { FirestoreService } from 'src/app/services/firebase/firestore.service';

import * as OperationActions from './operation.actions';
import { Activity } from 'src/app/models/v2/activity.model';

const MERCHANTS = RTDB_CONSTANTS.MERCHANTS;

@Injectable()
export class OperationEffects {
  getRealTimeOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.GET_REALTIME_ORDERS),
      mergeMap(({ selectedBranch, dateFilter }) =>
        this.rtdbService.listenToRealTimeOrders(selectedBranch, dateFilter).pipe(
          map((orders: RealTimeOrder[]) => {
            return OperationActions.setRealTimeOrders({ orders });
          }),
          catchError((err) => of(OperationActions.error({ error: err.message })))
        )
      )
    )
  );

  getOrderDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.GET_ORDER_DETAILS),
      mergeMap(({ id }) =>
        this.fsService.getOrderDetails(id, '').pipe(
          switchMap((orderDetails: OrderDetails) => [OperationActions.setOrderDetails({ orderDetails })]),
          catchError((err) => of(OperationActions.error({ error: err.message })))
        )
      )
    )
  );

  getOrderActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.GET_ORDER_ACTIVITIES),
      mergeMap(({ id }) =>
        this.fsService.getOrderActivities(id).pipe(
          map((_activities: any) => {
            const activities: Activity[] = _activities.a;
            return OperationActions.setSelectedOrderActivities({ activities });
          }),
          catchError((err) => of(OperationActions.error({ error: err.message })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private rtdbService: RTDBService, private fsService: FirestoreService) {}
}
