import { Branch } from 'src/app/models/data/branch.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { User } from './auth.model';

export interface State {
  user: User;
  merchantSelectedBranch: Branch;
  selectedBranch: Branch;
  merchant: MerchantOnboardingReducer;
}

interface Loading {
  loading: boolean;
}

export const initialState: State = {
  user: null,
  merchantSelectedBranch: null,
  selectedBranch: null,
  merchant: {
    createMerchantOnboarding: {
      loading: false,
    },
  },
};

interface MerchantOnboardingReducer {
  createMerchantOnboarding?: Loading;
}

const authReducer = createReducer(
  initialState,
  on(AuthActions.getUser, (state) => state),
  on(AuthActions.setMerchantSelectedBranch, (state, { merchantSelectedBranch }) => ({
    ...state,
    error: null,
    merchantSelectedBranch,
  })),
  on(AuthActions.setSelectedBranch, (state, { selectedBranch }) => ({
    ...state,
    error: null,
    selectedBranch,
  })),
  on(AuthActions.register, (state) => state),
  on(AuthActions.emailLogin, (state) => state),
  on(AuthActions.connectGoogle, (state) => state),
  on(AuthActions.connectFacebook, (state) => state),
  on(AuthActions.resetPassword, (state) => state),
  on(AuthActions.authenticated, (state, { user }) => ({ ...state, user })),
  on(AuthActions.notAuthenticated, () => ({ ...initialState })),
  on(AuthActions.error, (state, { error }) => ({ ...state, error })),
  on(AuthActions.createMerchantOnboarding, (state, { merchant }) => ({
    ...state,
    merchant: {
      createMerchantOnboarding: {
        merchant,
        loading: true,
      },
    },
  })),
  on(AuthActions.createMerchantOnboardingSuccess, (state, { response }) => ({
    ...state,
    merchant: {
      createMerchantOnboarding: {
        ...response.data,
        loading: false,
      },
    },
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
