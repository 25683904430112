import { MrSpeedyQuoteResponse } from './../../../models/integration/mrspeedy.model';
import { Injectable } from '@angular/core';

import { INTEGRATION } from 'src/app/core/constants/integration.constants';
import { MrSpeedyQuote, MrSpeedyOrder } from 'src/app/models/integration/mrspeedy.model';
import { OrderDetails, RealTimeOrder } from 'src/app/models/data/order.model';
import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';
import * as _ from 'lodash';
import { CourierService } from '../../utils/courier.service';
import { transformNumberToInternational } from 'src/app/core/utils/number';
import { DeliveryPlatformParam } from '@models/state/integration/actions.props';

@Injectable({
  providedIn: 'root',
})
export class MrspeedyService {
  private mrspeedyFunctions = this.cloudFunction.get(INTEGRATION.COURIER.MRSPEEDY);

  constructor(private cloudFunction: CloudFunctionsService, private courierService: CourierService) {}

  async quote(orders: OrderDetails[], merchantOwnerUid: string) {
    try {
      const res = await this.mrspeedyFunctions.getQuote({ orders, merchantOwnerUid });
      const response = res?.data?.data as MrSpeedyQuoteResponse;
      response['loading'] = false;
      response['price'] = response?.order?.payment_amount;
      return response;
    } catch (error) {
      const response = error;
      response['loading'] = true;
      response['price'] = 0;
      return response;
    }
  }

  async assign(deliveryPlatformParam: DeliveryPlatformParam) {
    try {
      const { orders, platform, platformPricing, merchantOwnerUid, branchId } = deliveryPlatformParam;
      const res = await this.mrspeedyFunctions.createOrder({
        orders: [...orders],
        branchId,
        merchantId: merchantOwnerUid,
      });
      const response = res?.data;
      response['courierOrderId'] = response?.order?.order_id;
      response['deliveryFee'] = response?.order?.delivery_fee_amount;
      return response;
    } catch (error) {
      return error;
    }
  }

  async cancel(branchId: string, merchantId: string, orderDetails: RealTimeOrder, courierOrderId: string) {
    try {
      const res = await this.mrspeedyFunctions.cancelOrder({
        branchId,
        merchantId,
        orderDetails,
        taskId: courierOrderId,
      });
      const response = res?.data?.data;
      return response;
    } catch (error) {
      return error;
    }
  }

  async getCourier(id: string, merchantOwnerUid: string) {
    try {
      const res = await this.mrspeedyFunctions.getCourier({ order_id: id, merchantOwnerUid });
      const response = res?.data?.data;
      return response;
    } catch (error) {
      return error;
    }
  }
}
