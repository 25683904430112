import { Component, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-fixed-plugin',
  templateUrl: './fixed-plugin.component.html',
  styleUrls: ['./fixed-plugin.component.scss'],
})
export class FixedPluginComponent {
  public sidebarColor = 'primary';
  public state = true;
  public dashboardColor = true;

  constructor(public toastr: ToastrService, private cd: ChangeDetectorRef) {}

  changeSidebarColor(color: string) {
    const sidebar = document.getElementsByClassName('sidebar')[0];
    const mainPanel = document.getElementsByClassName('main-panel')[0];

    this.sidebarColor = color;

    if (sidebar !== undefined) {
      sidebar.setAttribute('data', color);
    }
    if (mainPanel !== undefined) {
      mainPanel.setAttribute('data', color);
    }
    this.cd.detectChanges();
  }
  changeDashboardColor(color) {
    const body = document.getElementsByTagName('body')[0];
    if (body && color === 'white-content') {
      body.classList.add(color);
    } else if (body.classList.contains('white-content')) {
      body.classList.remove('white-content');
    }
    this.cd.detectChanges();
  }

  onChangeDashboardColor(event) {
    const body = document.getElementsByTagName('body')[0];
    if (this.dashboardColor === true) {
      this.changeDashboardColor('dark-content');
    } else {
      this.changeDashboardColor('white-content');
    }
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
    this.cd.detectChanges();
  }
  onChange(event) {
    const body = document.getElementsByTagName('body')[0];
    if (this.state === true) {
      body.classList.remove('sidebar-mini');
      this.showSidebarMessage('Sidebar mini deactivated...');
    } else {
      body.classList.add('sidebar-mini');
      this.showSidebarMessage('Sidebar mini activated...');
    }
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
    this.cd.detectChanges();
  }
  showSidebarMessage(message) {
    this.toastr.show('<span class="now-ui-icons ui-1_bell-53"></span>', message, {
      timeOut: 4000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-top-right',
    });
    this.cd.detectChanges();
  }
}
