import { PaymentsEffects } from './payments/payments.effects';
import { AggregationEffects } from './aggregation/aggregation.effects';
import { AuthEffects } from './auth/auth.effects';
import { DashboardEffects } from './dashboard/dashboard.effects';
import { IntegrationEffects } from './integration/integration.effects';
import { ReportsEffects } from './reports/reports.effects';
import { IOTEffects } from './iot/iot.effects';
import { StructureEffects } from './structure/structure.effects';
import { SettingsEffects } from './settings/settings.effects';

export const effects = [
  AggregationEffects,
  AuthEffects,
  ...DashboardEffects,
  IntegrationEffects,
  ReportsEffects,
  IOTEffects,
  PaymentsEffects,
  SettingsEffects,
  StructureEffects,
];
