import { Agent } from 'src/app/models/v2/agent.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as DeliveryActions from './delivery.actions';
import * as Time from 'src/app/core/utils/time';
import { Activities } from 'src/app/models/v2/activity.model';
import { Location } from '@models/v2/location.model';
import {
  OrderDetails,
  OrdersByDates,
  OrdersDetails,
  OrdersDetails2,
  RealTimeOrder,
  RealTimeOrders,
} from 'src/app/models/data/order.model';
import { RealTimeDeliveries } from '@models/v2/delivery.model';

export interface State {
  // SelectedBranch
  selectedBranch: object[];
  selectedBranchLocation: Location;
  selectedSorting: string;
  dateFilter: {
    selectedDateFilter: string; // YYYY/M/D
    selectedNextDateFilter: string;
  };
  // Real Time Deliveries
  realTimeDeliveries: RealTimeDeliveries;
  // Real Time Orders
  realTimeOrders: RealTimeOrders;
  realTimeAgents: Agent;
  agentsDetails: Agent;
  orderQueue: object[];
  assignQueuedOrder: RealTimeOrder;
  // Cached Orders
  ordersByDates: OrdersByDates;
  ordersDetails: OrdersDetails;
  ordersDetails2: OrdersDetails2;
  advanceOrders: any;
  updateOrderStatus: any;
  updateAgentStatus: any;
  updateDeliveryAddressStatus: any;
  selectedAdvanceOrder: any;
  ordersCalendar: any;
  ordersAggregation: any;
  // Selected Order
  selectedRealTimeOrder: RealTimeOrder;
  selectedOrderDetails: OrderDetails;
  // Get activities
  selectedOrderActivities: Activities;
  selectedAgentActivities: Activities;

  // Error
  error: Error;
}

export const initialState: State = {
  // Real Time Orders
  orderQueue: [],
  realTimeAgents: {
    loading: true,
    agents: [],
  },
  dateFilter: {
    selectedDateFilter: Time.moment$().format(Time.defaultFormat),
    selectedNextDateFilter: Time.moment$().format(Time.defaultFormat),
  },
  assignQueuedOrder: null,
  selectedRealTimeOrder: {
    loading: true,
  },
  // DELIVERY
  realTimeDeliveries: {
    loading: true,
    deliveries: [],
  },
  // ORDER
  realTimeOrders: {
    loading: true,
    orders: [],
  },
  updateOrderStatus: {
    loading: false,
  },
  updateAgentStatus: {
    loading: false,
  },
  updateDeliveryAddressStatus: {
    loading: false,
  },
  selectedOrderDetails: {
    loading: true,
  },
  advanceOrders: {
    orders: [],
    loading: true,
  },
  selectedAdvanceOrder: {
    loading: true,
  },
  ordersCalendar: {
    orders: [],
    loading: true,
  },
  ordersAggregation: {
    orders: [],
    loading: true,
  },
  ordersDetails: {
    loading: true,
    orders: {},
  },
  ordersDetails2: {
    loading: true,
    orders: [],
  },
  agentsDetails: {
    loading: true,
    agents: {},
  },
  ordersByDates: {
    loading: true,
    orders: {},
  },
  // BRANCH
  selectedBranch: null,
  selectedBranchLocation: null,
  // Get activities
  selectedOrderActivities: {
    loading: true,
    activities: [],
  },

  selectedSorting: 'time',

  selectedAgentActivities: {
    loading: true,
    activities: [],
  },

  error: null,
};

const deliveryReducer = createReducer(
  initialState,
  // Real Time Orders
  on(DeliveryActions.insertOrderToQueue, (state, { selectedRealTimeOrder }) => ({
    ...state,
    orderQueue: [...state.orderQueue, selectedRealTimeOrder],
  })),
  on(DeliveryActions.shiftOrderToQueue, (state, { orders }) => ({
    ...state,
    orderQueue: [...orders],
  })),
  on(DeliveryActions.assignQueuedOrder, (state, { assignQueuedOrder }) => ({
    ...state,
    assignQueuedOrder: { ...assignQueuedOrder },
  })),
  on(DeliveryActions.getRealTimeDeliveries, (state) => state),
  on(DeliveryActions.setRealTimeDeliveries, (state, { deliveries }) => ({
    ...state,
    realTimeDeliveries: {
      deliveries,
      loading: false,
    },
    error: null,
  })),
  on(DeliveryActions.getRealTimeOrders, (state) => state),
  on(DeliveryActions.setRealTimeOrders, (state, { orders }) => ({
    ...state,
    realTimeOrders: {
      orders,
      loading: false,
    },
    error: null,
  })),
  on(DeliveryActions.setNextDayRealTimeOrders, (state, { orders }) => ({
    ...state,
    realTimeOrders: {
      orders: [
        ...state.realTimeOrders.orders.filter((order) => {
          const nextDayOrders = [...orders];
          if (
            !nextDayOrders.find((nextDayOrder) => {
              return order.id === nextDayOrder.id;
            })
          ) {
            return order;
          }
        }),
        ...orders,
      ],
      loading: false,
    },
    error: null,
  })),
  on(DeliveryActions.initializeRealTimeOrders, (state) => ({
    ...state,
    error: null,
    realTimeOrders: {
      loading: true,
      orders: [],
    },
  })),
  // Selected Order
  on(DeliveryActions.setSelectedRealTimeOrder, (state, { selectedRealTimeOrder }) => ({
    ...state,
    error: null,
    selectedRealTimeOrder: {
      ...selectedRealTimeOrder,
      loading: false,
    },
  })),
  on(DeliveryActions.setSelectedOrderDetails, (state, { selectedOrderDetails }) => ({
    ...state,
    error: null,
    selectedOrderDetails: {
      ...selectedOrderDetails,
      loading: false,
    },
  })),
  on(DeliveryActions.initializeSelectedOrderDetails, (state) => ({
    ...state,
    error: null,
    selectedOrderDetails: {
      loading: true,
    },
  })),

  // Agent Details
  on(DeliveryActions.getAgentDetails, (state) => state),
  on(DeliveryActions.setAgentDetails, (state, { agentDetails }) => ({
    ...state,
    error: null,
    agentsDetails: {
      agents: {
        ...state.agentsDetails.agents,
        [agentDetails.uid]: {
          ...agentDetails,
          loading: false,
        },
      },
    },
  })),

  // Get agent activities
  on(DeliveryActions.getAgentActivities, (state) => state),
  on(DeliveryActions.setSelectedAgentActivities, (state, { activities }) => ({
    ...state,
    error: null,
    selectedAgentActivities: {
      activities,
      loading: false,
    },
  })),

  // Update Order Status
  on(DeliveryActions.updateOrderStatus, (state) => ({
    ...state,
    updateOrderStatus: {
      loading: true,
    },
  })),
  on(DeliveryActions.updateOrderStatusSuccess, (state, { result }) => ({
    ...state,
    updateOrderStatus: {
      loading: false,
      ...result,
    },
  })),
  on(DeliveryActions.updateOrderStatusError, (state, { error }) => ({
    ...state,
    updateOrderStatus: {
      loading: true,
      ...error,
    },
  })),

  // Update Agent Status
  on(DeliveryActions.updateAgentStatus, (state) => ({
    ...state,
    updateAgentStatus: {
      loading: true,
    },
  })),
  on(DeliveryActions.updateAgentStatusSuccess, (state, { result }) => ({
    ...state,
    updateAgentStatus: {
      loading: false,
      ...result,
    },
  })),
  on(DeliveryActions.updateAgentStatusError, (state, { error }) => ({
    ...state,
    updateAgentStatus: {
      loading: false,
      ...error,
    },
  })),

  // Update Delivery Location
  on(DeliveryActions.updateDeliveryAddress, (state) => ({
    ...state,
    updateDeliveryAddressStatus: {
      loading: true,
    },
  })),
  on(DeliveryActions.updateDeliveryAddressSuccess, (state, { result }) => ({
    ...state,
    updateDeliveryAddressStatus: {
      loading: false,
      ...result,
    },
  })),
  on(DeliveryActions.updateDeliveryAddressError, (state, { error }) => ({
    ...state,
    updateDeliveryAddressStatus: {
      loading: false,
      ...error,
    },
  })),

  // Orders Details
  on(DeliveryActions.getOrderDetails, (state) => state),
  on(DeliveryActions.setOrderDetails, (state, { orderDetails }) => ({
    ...state,
    error: null,
    ordersDetails: {
      orders: {
        ...state.ordersDetails.orders,
        [orderDetails.id]: {
          ...orderDetails,
          loading: false,
        },
      },
    },
    ordersDetails2: {
      orders: [...state.ordersDetails2.orders, orderDetails],
      loading: false,
    },
  })),

  // Get order activities
  on(DeliveryActions.getOrderActivities, (state) => state),
  on(DeliveryActions.setSelectedOrderActivities, (state, { activities }) => ({
    ...state,
    error: null,
    selectedOrderActivities: {
      activities,
      loading: false,
    },
  })),

  on(DeliveryActions.getRealTimeAgents, (state) => state),
  on(DeliveryActions.setRealTimeAgents, (state, { agents }) => ({
    ...state,
    realTimeAgents: {
      agents,
      loading: false,
    },
    error: null,
  })),

  // Selected Branch
  on(DeliveryActions.setSelectedBranch, (state, { selectedBranch }) => ({
    ...state,
    error: null,
    selectedBranch: [selectedBranch],
  })),
  on(DeliveryActions.setSelectedBranchLocation, (state, { location }) => ({
    ...state,
    error: null,
    selectedBranchLocation: location,
  })),

  on(DeliveryActions.setSelectedSorting, (state, { selectedSorting }) => ({
    ...state,
    error: null,
    selectedSorting,
  })),

  // Date Filter
  on(DeliveryActions.setSelectedDateFilter, (state, { selectedDateFilter }) => ({
    ...state,
    error: null,
    dateFilter: {
      ...state.dateFilter,
      selectedDateFilter,
    },
  })),
  on(DeliveryActions.setSelectedNextDateFilter, (state, { selectedNextDateFilter }) => ({
    ...state,
    error: null,
    dateFilter: {
      ...state.dateFilter,
      selectedNextDateFilter,
    },
  })),

  // Reset
  on(DeliveryActions.reset, () => ({ ...initialState })),

  // Errors
  on(DeliveryActions.error, (state, { error }) => ({
    ...state,
    error,
  })),

  // Advance Orders
  on(DeliveryActions.setAdvanceOrders, (state, { orders }) => ({
    ...state,
    advanceOrders: {
      orders: orders,
      loading: false,
    },
  })),

  on(DeliveryActions.setSelectedAdvanceOrders, (state, { advanceOrder }) => ({
    ...state,
    selectedAdvanceOrder: {
      advanceOrder,
      loading: false,
    },
  })),

  // Calendar Summary
  on(DeliveryActions.setMonthlyOrdersAggregation, (state, { aggregation }) => ({
    ...state,
    ordersAggregation: {
      orders: [...state.ordersAggregation.orders, aggregation],
      loading: false,
    },
  })),

  // Calendar Orders
  on(DeliveryActions.setMonthlyRealTimeOrders, (state, { orders }) => ({
    ...state,
    ordersCalendar: {
      orders: [...state.ordersCalendar.orders, orders],
      loading: false,
    },
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return deliveryReducer(state, action);
}
