import { Action, createReducer, on } from '@ngrx/store';
import * as ReportsActions from './reports.actions';

export interface State {
  dailyOrders: object;
  weeklyOrders: object;
  monthlyOrders: object;
  dailyDeliveries: object;
  weeklyDeliveries: object;
  monthlyDeliveries: object;
  dailyPayments: object;
  weeklyPayments: object;
  monthlyPayments: object;
  dailySales: object;
  weeklySales: object;
  monthlySales: object;
  selectedBranch: object[];
}

export const initialState: State = {
  dailyOrders: null,
  weeklyOrders: null,
  monthlyOrders: null,
  dailyDeliveries: null,
  weeklyDeliveries: null,
  monthlyDeliveries: null,
  dailyPayments: null,
  weeklyPayments: null,
  monthlyPayments: null,
  dailySales: null,
  weeklySales: null,
  monthlySales: null,
  selectedBranch: null,
};

const reportsReducer = createReducer(
  initialState,
  // ORDERS
  on(ReportsActions.getDailyOrdersReports, (state) => state),
  on(ReportsActions.setDailyOrdersReports, (state, month) => ({
    ...state,
    dailyOrders: {
      ...month.daily,
    },
    error: null,
  })),
  on(ReportsActions.getMonthlyOrdersReports, (state) => state),
  on(ReportsActions.setMonthlyOrdersReports, (state, month) => ({
    ...state,
    monthlyOrders: {
      ...month.monthly,
    },
    error: null,
  })),
  on(ReportsActions.getWeeklyOrdersReports, (state) => state),
  on(ReportsActions.setWeeklyOrdersReports, (state, weekly) => ({
    ...state,
    weeklyOrders: {
      ...weekly.weekly,
    },
    error: null,
  })),
  // DELIVERIES
  on(ReportsActions.getDailyDeliveriesReports, (state) => state),
  on(ReportsActions.setDailyDeliveriesReports, (state, month) => ({
    ...state,
    dailyDeliveries: {
      ...month.daily,
    },
    error: null,
  })),
  on(ReportsActions.getMonthlyDeliveriesReports, (state) => state),
  on(ReportsActions.setMonthlyDeliveriesReports, (state, month) => ({
    ...state,
    monthlyDeliveries: {
      ...month.monthly,
    },
    error: null,
  })),
  on(ReportsActions.getWeeklyDeliveriesReports, (state) => state),
  on(ReportsActions.setWeeklyDeliveriesReports, (state, weekly) => ({
    ...state,
    weeklyDeliveries: {
      ...weekly.weekly,
    },
    error: null,
  })),
  // PAYMENTS
  on(ReportsActions.getDailyPaymentsReports, (state) => state),
  on(ReportsActions.setDailyPaymentsReports, (state, month) => ({
    ...state,
    dailyPayments: {
      ...month.daily,
    },
    error: null,
  })),
  on(ReportsActions.getMonthlyPaymentsReports, (state) => state),
  on(ReportsActions.setMonthlyPaymentsReports, (state, month) => ({
    ...state,
    monthlyPayments: {
      ...month.monthly,
    },
    error: null,
  })),
  on(ReportsActions.getWeeklyPaymentsReports, (state) => state),
  on(ReportsActions.setWeeklyPaymentsReports, (state, weekly) => ({
    ...state,
    weeklyPayments: {
      ...weekly.weekly,
    },
    error: null,
  })),
  // SALES
  on(ReportsActions.getDailySalesReports, (state) => state),
  on(ReportsActions.setDailySalesReports, (state, month) => ({
    ...state,
    dailySales: {
      ...month.daily,
    },
    error: null,
  })),
  on(ReportsActions.getMonthlySalesReports, (state) => state),
  on(ReportsActions.setMonthlySalesReports, (state, month) => ({
    ...state,
    monthlySales: {
      ...month.monthly,
    },
    error: null,
  })),
  on(ReportsActions.getWeeklySalesReports, (state) => state),
  on(ReportsActions.setWeeklySalesReports, (state, weekly) => ({
    ...state,
    weeklySales: {
      ...weekly.weekly,
    },
    error: null,
  })),
  on(ReportsActions.setSelectedBranch, (state, { selectedBranch }) => ({
    ...state,
    error: null,
    selectedBranch: [selectedBranch],
  })),
  on(ReportsActions.reset, () => ({ ...initialState })),
  on(ReportsActions.error, (state) => state)
);

export function reducer(state: State | undefined, action: Action) {
  return reportsReducer(state, action);
}
