import { PaymentLink } from '@models/v2/payment.model';
import { PaymentService } from '@payment/payment.service';
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

// services

// state
import * as PaymentsActions from './payments.actions';
import { Task } from '@models/data/task.model';
import { merge } from 'lodash';

@Injectable()
export class PaymentsEffects {
  constructor(private actions$: Actions, private paymentService: PaymentService) {}

  createPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentsActions.CREATE_PAYMENT_LINK),
        mergeMap(({ order }) =>
          this.paymentService.createPayment(order).pipe(
            map((link: PaymentLink) => {
              return PaymentsActions.createPaymentLinkSuccess({ link });
            }),
            catchError((err) => of(PaymentsActions.createPaymentLinkError({ error: err })))
          )
        )
      ),
    { dispatch: true }
  );

  updatePaymentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.UPDATE_PAYMENT_STATUS),
      mergeMap(({ user, id, fulfillAt, status, timezone, autoAllocate }) =>
        this.paymentService.updatePayment(user, id, fulfillAt, status, timezone, autoAllocate).pipe(
          map((result) => {
            return PaymentsActions.updatePaymentStatusSuccess({ result: result.data });
          }),
          catchError((err) => of(PaymentsActions.updatePaymentStatusError({ error: err })))
        )
      )
    )
  );
}
