import { RemoteConfigService } from './../firebase/remote.config.service';
import { tap } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { DefaultConfigs } from '../../models/data/defaults.model';

// State
import { Store } from '@ngrx/store';
import { StoreRootState } from '../../state/state.reducers';
import * as fromContent from 'src/app/state/content/content.selector';

declare let google: any;

@Injectable({
  providedIn: 'root',
})
export class MapService {
  autoCompleteService: google.maps.places.AutocompleteService;
  country$: Observable<any>;
  country: string;
  constructor(public zone: NgZone, private rcs: RemoteConfigService, private store: Store<StoreRootState>) {
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.initializeCountry();
  }

  initializeCountry() {
    this.country$ = this.store.select(fromContent.selectContentDefaults);
    this.country$.pipe(
      tap((defaults: DefaultConfigs) => {
        this.country = defaults.country;
      })
    );
  }

  getPlacePredictions(query: string): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable((observer) => {
      this.autoCompleteService.getPlacePredictions(
        { input: query, componentRestrictions: { country: this.country } },
        (places_predictions, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            this.zone.run(() => {
              observer.next([]);
              observer.complete();
            });
          } else {
            this.zone.run(() => {
              observer.next(places_predictions);
              observer.complete();
            });
          }
        }
      );
    });
  }
}
