import { Injectable } from '@angular/core';

import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';

import { OrderDetails, RealTimeOrder } from 'src/app/models/data/order.model';
import { LalamoveQuote, PlaceOrder, QuotedFee } from 'src/app/models/integration/lalamove.model';

import { INTEGRATION } from 'src/app/core/constants/integration.constants';
import { transformNumberToNational } from 'src/app/core/utils/number';
import { DeliveryPlatformParam } from '@models/state/integration/actions.props';

@Injectable({
  providedIn: 'root',
})
export class LalamoveService {
  private lalamoveFunctions = this.cloudFunction.get(INTEGRATION.COURIER.LALAMOVE);

  constructor(private cloudFunction: CloudFunctionsService) {}

  async quote(orderDetails: OrderDetails[], merchantId: string) {
    try {
      const res = await this.lalamoveFunctions.getQuote({ orderDetails: [...orderDetails], merchantId });
      const response = res?.data?.data;
      response['loading'] = false;
      response['price'] = Number(response?.totalFee).toFixed(2);
      return response;
    } catch (error) {
      const response = error;
      response['loading'] = true;
      response['price'] = 0;
      return response;
    }
  }

  async assign(deliveryPlatformParam: DeliveryPlatformParam) {
    const { orders, platform, platformPricing, merchantOwnerUid, branchId } = deliveryPlatformParam;
    try {
      const res = await this.lalamoveFunctions.createOrder({
        orders,
        platformPricing,
        merchantId: merchantOwnerUid,
      });
      const response = res?.data;
      response['courierOrderId'] = response.orderRef;
      response['deliveryFee'] = platformPricing.totalFee;
      return response;
    } catch (error) {
      return error;
    }
  }

  async cancel(branchId: string, merchantId: string, orderDetails: RealTimeOrder, courierOrderId: string) {
    try {
      const res = await this.lalamoveFunctions.cancelOrder({
        branchId,
        merchantId,
        orderDetails,
        taskId: courierOrderId,
      });
      const response = res?.data?.data;
      return response;
    } catch (error) {
      return error;
    }
  }

  async getOrder(branchId: string, merchantId: string, orderDetails: RealTimeOrder, courierOrderId: string) {
    try {
      const res = await this.lalamoveFunctions.getOrder({
        branchId,
        merchantId,
        orderDetails,
        id: courierOrderId,
      });
      const response = res?.data?.data;
      return response;
    } catch (error) {
      return error;
    }
  }

  async getCourier(branchId: string, merchantId: string, orderDetails: RealTimeOrder, courierOrderId: string) {
    try {
      const driverId = orderDetails.cId;
      const res = await this.lalamoveFunctions.getCourier({
        branchId,
        merchantId,
        orderDetails,
        orderId: courierOrderId,
        driverId,
      });
      const response = res?.data?.data;
      return response;
    } catch (error) {
      return error;
    }
  }

  async getLocation(branchId: string, merchantId: string, orderDetails: RealTimeOrder, courierOrderId: string) {
    try {
      const driverId = orderDetails.cId;
      const res = await this.lalamoveFunctions.getLocation({
        branchId,
        merchantId,
        orderDetails,
        orderId: courierOrderId,
        driverId,
      });
      const response = res?.data?.data;
      return response;
    } catch (error) {
      return error;
    }
  }
}
