import { Component, ViewChild, EventEmitter, Output, AfterViewInit } from '@angular/core';

declare const google: any;

@Component({
  selector: 'app-search-place',
  templateUrl: './search-place.html',
  styleUrls: ['./search-place.scss'],
})
export class SearchPlaceComponent implements AfterViewInit {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addressText') addressText: any;

  autocompleteInput: string;
  queryWait: boolean;

  constructor() {}

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement, {
      componentRestrictions: { country: 'PH' }, // update for dynamic merchant's country
      types: ['geocode', 'establishment'], // best combination for finding places
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.setPlace(place);
    });
  }

  setPlace(place) {
    this.setAddress.emit(place);
  }

  clearInput() {
    this.addressText.nativeElement.value = '';
  }
}
