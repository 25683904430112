import { Merchant } from '@models/v2/merchant.model';
import { PaymentLink } from '@models/v2/payment.model';
import { Task } from '@models/v2/task.model';
import { createAction, props } from '@ngrx/store';

export const CREATE_PAYMENT_LINK = '[Payments] Create Payment Link';
export const CREATE_PAYMENT_LINK_SUCCESS = '[Payments] Create Payment Link Success';
export const CREATE_PAYMENT_LINK_ERROR = '[Payments] Create Payment Link Error';

export const RESET = '[Payments] Reset Reports';
export const ERROR = '[Payments] Error';
export const SUCCESS = '[Payments] Success';

export const UPDATE_PAYMENT_STATUS = '[ Payments ] Update Payment Status';
export const UPDATE_PAYMENT_STATUS_SUCCESS = '[ Payments ] Update Payment Status Success';
export const UPDATE_PAYMENT_STATUS_ERROR = '[ Payments ] Update Payment Status Error';

export const updatePaymentStatus = createAction(
  UPDATE_PAYMENT_STATUS,
  props<{ user: Merchant; id: string; fulfillAt: string; status: number; timezone: string; autoAllocate: number }>()
);
export const updatePaymentStatusSuccess = createAction(UPDATE_PAYMENT_STATUS_SUCCESS, props<{ result }>());
export const updatePaymentStatusError = createAction(UPDATE_PAYMENT_STATUS_ERROR, props<{ error: Error }>());

export const createPaymentLink = createAction(CREATE_PAYMENT_LINK, props<{ order: Task }>());

export const createPaymentLinkSuccess = createAction(CREATE_PAYMENT_LINK_SUCCESS, props<{ link: PaymentLink }>());
export const createPaymentLinkError = createAction(CREATE_PAYMENT_LINK_ERROR, props<{ error: Error }>());

export const reset = createAction(RESET);
export const error = createAction(ERROR, props<{ error: Error }>());
export const success = createAction(SUCCESS);
