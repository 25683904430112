import { Injectable } from '@angular/core';
import { moment$ } from '../../core/utils/time';

@Injectable({
  providedIn: 'root',
})
export class IDUtilService {
  constructor() {}

  getShortId(initial) {
    return `${initial}${Date.now().toString(36)}`;
  }

  getLongId(initial) {
    return `${initial}${Date.now()}`;
  }

  getTimestampFromShortId(shortId: string) {
    return this.formatDate(new Date(parseInt(shortId.substr(1), 36)));
  }

  getTimeFromShortId(shortId: string) {
    return this.formatTime(new Date(parseInt(shortId.substr(1), 36)));
  }

  getTimestampFromLongId(longId) {
    return new Date(parseInt(longId, 36));
  }

  formatTime(date) {
    const time = date.toLocaleTimeString();
    return `${time}`;
  }

  formatDate(date) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const day = date.getDate();
    const time = date.getHours();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${monthNames[monthIndex]} ${day}, ${year}`;
  }
}
