import { map, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';

import * as IOTActions from './iot.actions';
import { RTDBService } from 'src/app/services/firebase/rtdb.service';
import { FirestoreService } from 'src/app/services/firebase/firestore.service';
import { IOTService } from 'src/app/services/iot/iot.service';

@Injectable()
export class IOTEffects {
  getLatestScore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IOTActions.GET_LATEST_SCORE),
        mergeMap(({ building }) =>
          this.rtdbService.getLatestScore(building).pipe(
            map((score: any) => {
              return IOTActions.setLatestScore({ score });
            }),
            catchError((err) => of(IOTActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getSensors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IOTActions.GET_IOT_DEVICES),
        mergeMap(({ building }) =>
          this.fsService.getDevices(building).pipe(
            map((devices: any) => {
              return IOTActions.setIOTDevices({ devices });
            }),
            catchError((err) => of(IOTActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getDailySensor$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IOTActions.GET_DAILY_SENSOR),
        mergeMap(({ year, month, day, branch, building, room }) =>
          this.rtdbService.getDailySensor(year, month, day, branch, building, room).pipe(
            map((daily: any) => {
              return IOTActions.setDailySensorData({ daily });
            }),
            catchError((err) => of(IOTActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getIOTHistoricalQuery$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IOTActions.GET_IOT_HISTORICAL_DATA),
        mergeMap(({ roomId, date, hour }) =>
          this.iotService.getIOTHistoricalQuery({ roomId, date, hour }).pipe(
            map(({ data }) => {
              const historicalData = JSON.parse(data);
              return IOTActions.setIOTHistoricalData({ historicalData });
            }),
            catchError((err) => of(IOTActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );
  constructor(
    private actions$: Actions,
    private fsService: FirestoreService,
    private rtdbService: RTDBService,
    private iotService: IOTService
  ) {}
}
