import { Action, createReducer, on } from '@ngrx/store';
import * as StructureActions from './structure.actions';

import { PageStructure } from 'src/app/models/structure/page.structure.model';

export interface State extends PageStructure {
  error: Error;
}

export const initialState: State = {
  error: null,
};

const structuresReducer = createReducer(
  initialState,
  on(StructureActions.setPageStructure, (state, { structure }) => ({
    ...state,
    ...structure,
    error: null,
  })),
  on(StructureActions.reset, () => ({ ...initialState })),
  on(StructureActions.error, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return structuresReducer(state, action);
}
