import { createAction, props } from '@ngrx/store';

import { DeliveryAggregation } from 'src/app/models/aggregation/delivery.aggregation.model';
import { OrderAggregation } from 'src/app/models/aggregation/order.aggregation.model';
import { PriorityAggregation } from 'src/app/models/aggregation/priority.aggregation.model';

// Orders
export const GET_ORDERS_AGGREGATION = '[Aggregation] Get Orders Aggregation';
export const SET_ORDERS_AGGREGATION = '[Aggregation] Set Orders Aggregation';
export const INITIALIZE_ORDERS_AGGREGATION = '[Aggregation] Initialize Orders Aggregation';

export const GET_PRIORITIES_AGGREGATION = '[Aggregation] Get Priorities Aggregation';
export const SET_PRIORITIES_AGGREGATION = '[Aggregation] Set Priorities Aggregation';
export const INITIALIZE_PRIORITIES_AGGREGATION = '[Aggregation] Initialize Priorities Aggregation';

export const GET_DELIVERIES_AGGREGATION = '[Aggregation] Get Deliveries Aggregation';
export const SET_DELIVERIES_AGGREGATION = '[Aggregation] Set Deliveries Aggregation';
export const INITIALIZE_DELIVERIES_AGGREGATION = '[Aggregation] Initialize Deliveries Aggregation';

export const GET_PAYMENT_AGGREGATION = '[Aggregation] Get Payment Aggregation';
export const SET_PAYMENT_AGGREGATION = '[Aggregation] Set Payment Aggregation';
export const INITIALIZE_PAYMENT_AGGREGATION = '[Aggregation] Initialize Payment Aggregation';

export const RESET = '[Aggregation] Reset';
export const SUCCESS = '[Aggregation] Success';
export const ERROR = '[Aggregation] Error';

export const getOrdersAgregation = createAction(
  GET_ORDERS_AGGREGATION,
  props<{ selectedBranch: string; dateFilter: string }>()
);
export const setOrdersAgregation = createAction(SET_ORDERS_AGGREGATION, props<{ aggregation: OrderAggregation }>());
export const initializeOrdersAggregation = createAction(INITIALIZE_ORDERS_AGGREGATION);

export const getPrioritiesAgregation = createAction(
  GET_PRIORITIES_AGGREGATION,
  props<{ selectedBranch: string; dateFilter: string }>()
);
export const setPrioritiesAgregation = createAction(
  SET_PRIORITIES_AGGREGATION,
  props<{ aggregation: PriorityAggregation }>()
);
export const initializePrioritiesAggregation = createAction(INITIALIZE_PRIORITIES_AGGREGATION);

export const getDeliveriesAgregation = createAction(
  GET_DELIVERIES_AGGREGATION,
  props<{ selectedBranch: string; dateFilter: string }>()
);
export const setDeliveriesAgregation = createAction(
  SET_DELIVERIES_AGGREGATION,
  props<{ aggregation: DeliveryAggregation }>()
);
export const initializeDeliveriesAggregation = createAction(INITIALIZE_DELIVERIES_AGGREGATION);

export const reset = createAction(RESET);
export const success = createAction(SUCCESS);
export const error = createAction(ERROR, props<{ error: Error }>());
