import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { Platform } from 'src/app/models/data/platform.model';
import { REMOTE_CONFIG } from 'src/app/core/constants/remote.config.constants';

import { Store } from '@ngrx/store';
import { StoreRootState } from 'src/app/state/state.reducers';
import * as fromContent from 'src/app/state/content/content.selector';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class CourierService {
  private deliveryPlatforms = {};
  private noCourier: Platform;

  constructor(private store: Store<StoreRootState>) {
    store.select(fromContent.selectContentIntegrationDelivery).subscribe((deliveryPlatforms: Platform[]) => {
      if (deliveryPlatforms && deliveryPlatforms.length > 0) {
        deliveryPlatforms.forEach((platform) => {
          if (platform) {
            this.deliveryPlatforms[platform.id] = { ...platform };
          }
        });
        this.noCourier = this.deliveryPlatforms[REMOTE_CONFIG.INTEGRATION.DELIVERY.NOCOURIER];
      }
    });
  }

  getName(courier: string) {
    return this.deliveryPlatforms[courier]?.name || this.noCourier.name;
  }

  getLogo(courier: string) {
    return this.deliveryPlatforms[courier]?.id || this.noCourier.id;
  }

  getTheme(courier: string) {
    return this.deliveryPlatforms[courier]?.theme || this.noCourier.theme;
  }

  getQuantity(items: any[]) {
    let quantity = 0;
    items.map((item) => {
      if (typeof item.quantity !== 'undefined') {
        quantity += item.quantity;
      }
    });
    return quantity;
  }
}
