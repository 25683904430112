import { mergeMap, map, catchError, first, tap, switchMap, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
// Service
import { RTDBService } from 'src/app/services/firebase/rtdb.service';
// State
import * as AggregationActions from './aggregation.actions';
// Models
import { OrderAggregation } from 'src/app/models/aggregation/order.aggregation.model';
import { DeliveryAggregation } from 'src/app/models/aggregation/delivery.aggregation.model';
import { PriorityAggregation } from 'src/app/models/aggregation/priority.aggregation.model';
import { GlobalDataService } from '@shared/global.shared.service';

@Injectable()
export class AggregationEffects {
  getOrdersAggregation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AggregationActions.GET_ORDERS_AGGREGATION),
      mergeMap(({ selectedBranch, dateFilter }) =>
        this.rtdbService.listenToDailyBranchOrderAggregation(selectedBranch, dateFilter).pipe(
          takeUntil(this.global.RealTimeAggregationNotifier$),
          map((aggregation: OrderAggregation) => {
            return AggregationActions.setOrdersAgregation({ aggregation });
          }),
          catchError((err) => of(AggregationActions.error({ error: err.message })))
        )
      )
    )
  );

  getPrioritiesAggregation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AggregationActions.GET_PRIORITIES_AGGREGATION),
      mergeMap(({ selectedBranch, dateFilter }) =>
        this.rtdbService.listenToBranchPriorityAggregation(selectedBranch, dateFilter).pipe(
          map((aggregation: PriorityAggregation) => {
            return AggregationActions.setPrioritiesAgregation({ aggregation });
          }),
          catchError((err) => of(AggregationActions.error({ error: err.message })))
        )
      )
    )
  );

  getDeliveriesAggregation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AggregationActions.GET_DELIVERIES_AGGREGATION),
      mergeMap(({ selectedBranch, dateFilter }) =>
        this.rtdbService.listenToBranchDeliveryAggregation(selectedBranch, dateFilter).pipe(
          map((aggregation: DeliveryAggregation) => {
            return AggregationActions.setDeliveriesAgregation({
              aggregation,
            });
          }),
          catchError((err) => of(AggregationActions.error({ error: err.message })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private rtdbService: RTDBService, private global: GlobalDataService) {}
}
